import React, {useEffect, useState} from 'react';
import {CheckIfStringExists} from "../../helpers";


export default function ProgressBar({ pathname }) {
  const [progress, SetProgress] = useState(25);
  const [ProgressBarValue, SetProgressBarValue] = useState({
    Veicolo: true,
    Proprietario: false,
    Assicurazione: false,
    Preventivo: false,
  });
  const UpdateHeaderProgressBar = (pathname) => {
    SetProgressBarValue({
      Proprietario: true,
      Veicolo: CheckIfStringExists(pathname, [
        "/assicurazione",
        "/polizza/auto",
        "/polizza/autocarro",
        "/polizza/moto",
        "/tipo-di-polizza/rinnovo/autocarro",
        "/tipo-di-polizza/rinnovo/moto",
        "/tipo-di-polizza/rinnovo/auto",
        "/tipo-di-polizza/nuova/auto",
        "/tipo-di-polizza/nuova/moto",
        "/tipo-di-polizza/nuova/autocarro",
        "/veicolo",
        "/confermare-dettagli",
        "/dettagli-completati",
        "/bonus-famiglia",
        "/merito",
        "/incidenti",
        "/seleziona-guida",
        "/gancio-di-traino",
        "/limitazioni-alle-rivalse",
        "/risarcimento-diretto",
        "/preventivo"
      ]),
      Assicurazione: CheckIfStringExists(pathname, [
        "/dettagli-completati",
        "/bonus-famiglia",
        "/merito",
        "/incidenti",
        "/seleziona-guida",
        "/gancio-di-traino",
        "/limitazioni-alle-rivalse",
        "/risarcimento-diretto",
        "/preventivo"
      ]),
      Preventivo: CheckIfStringExists(pathname, [
        "/preventivo"
      ]),
    });
  };
  useEffect(() => {
    if (CheckIfStringExists(pathname, [
      "/",
      "/proprietario",
      "/proprietario",
      "/indirizzo",
      "/indirizzo",
      "/nato",
      "/informazioni",
      "/privacy",
    ])) {
      SetProgress(25);
    }
    if (CheckIfStringExists(pathname, [
      "/assicurazione",
      "/polizza/auto",
      "/polizza/autocarro",
      "/polizza/moto",
      "/tipo-di-polizza/rinnovo/autocarro",
      "/tipo-di-polizza/rinnovo/moto",
      "/tipo-di-polizza/rinnovo/auto",
      "/tipo-di-polizza/nuova/auto",
      "/tipo-di-polizza/nuova/moto",
      "/tipo-di-polizza/nuova/autocarro",
      "/veicolo",
      "/confermare-dettagli",
    ])) {
      SetProgress(50);
    }
    if (CheckIfStringExists(pathname, [
      "/dettagli-completati",
      "/bonus-famiglia",
      "/merito",
      "/incidenti",
      "/seleziona-guida",
      "/gancio-di-traino",
      "/limitazioni-alle-rivalse",
      "/risarcimento-diretto",
    ])) {
      SetProgress(75);
    }
    if (CheckIfStringExists(pathname, [
      "/preventivo"
    ])) {
      SetProgress(100);
    }
    UpdateHeaderProgressBar(pathname);
  }, [pathname])
  return (
      <section className="col-full form_section">
        <div className="progressbar">
          <ol className="step_form">
            <li className="current">Proprietario</li>
            <li className={ProgressBarValue.Veicolo ? "current" : ""}>
              Veicolo
            </li>
            <li className={ProgressBarValue.Assicurazione ? "current" : ""}>
              Assicurazione
            </li>
            <li className={ProgressBarValue.Preventivo ? "current" : ""}>
              Preventivo
            </li>
          </ol>
          <div className="loader-progress-bar">
            <div className={`loader-bar progress_${progress}`}/>
          </div>
        </div>
      </section>
  )
}
