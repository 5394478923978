import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { FilterArrayByKeyValue, NavigateTo } from "../helpers";
import { GetItem, SetItem } from "../service/session";

export default function LimitationsScreen({ AgentAvatar, SeoContent }) {
  const H = useHistory();
  const { data } = GetItem("INS_TYPE");
  const InsuranceType = data;
  let SEO = null;
  if (InsuranceType === "A") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }

  const [isReady, SetIsReady] = useState(false);
  const [Limitations, SetLimitations] = useState(false);

  const SelectAnswer = ({ target: { value } }) => {
    SetLimitations(value);
    SetIsReady(true);
  };

  const goToNextStep = () => {
    SetItem("LIMITATIONS", Limitations);
    NavigateTo({
      path: "/risarcimento-diretto",
      navigator: H,
    });
  };

  const SetOldData = () => {
    let s_Limitations = GetItem("LIMITATIONS");
    if (s_Limitations.data) {
      SetLimitations(s_Limitations.data);
      SetIsReady(true);
    }
  };

  useEffect(() => {
    SetOldData();
  }, []);

  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO && SEO.result.title}</title>
          <meta name="title" content={SEO && SEO.result.meta_title} />
          <meta
            name="description"
            content={SEO && SEO.result.meta_description}
          />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  <fieldset>
                    <label className="label">
                      Vuoi inserire limitazioni alle rivalse?
                    </label>

                    <ul className="standard_radio inline">
                      <li>
                        <input
                          type="radio"
                          id="no_limitations"
                          name="limitations"
                          value="no"
                          checked={Limitations === "no"}
                          onChange={SelectAnswer}
                        />
                        <label htmlFor="no_limitations">No</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="yes_limitations"
                          name="limitations"
                          value="yes"
                          checked={Limitations === "yes"}
                          onChange={SelectAnswer}
                        />
                        <label htmlFor="yes_limitations">Si</label>
                      </li>
                    </ul>
                  </fieldset>

                  <a className="" href="#" target="_blank">
                    <fieldset>
                      <span style={{ color: "red" }}>
                        Vuoi approfondire meglio?
                      </span>
                    </fieldset>
                  </a>

                  <div className="button_container">
                    <button
                      style={{
                        opacity: isReady ? 1 : 0.5,
                        transform: isReady ? "scale(1)" : "scale(.9)",
                        pointerEvents: isReady ? "all" : "none",
                      }}
                      className="cta green ml280"
                      onClick={goToNextStep}
                    >
                      Prosegui
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
