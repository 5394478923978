import React , { useState } from 'react';
import logoExample from "../../assets/images/logo_conte.png";
import  {CSSTransition}  from "react-transition-group";
import Arrow from "../../assets/images/arrow.svg";

export default function ResultCard(){
    const [showDetails, SetShowDetails] = useState(false);
    return (
        <div className="list_schede_item_container">
        <div className="list_schede_item custom">
          <ul>
            <li>
              <img src={logoExample} />
              <p>Emissione immediata</p>
              <p>Dispositivo satellitare</p>
              <p>Polizza sospendibile</p>
              <p>
                Massimale RC:{" "}
                <strong>6.070.000 / 1.220.000</strong>
              </p>
              <p>
                Franchigia RC: <strong>€ 0,00</strong>
              </p>
              <p>
                RC con risarcimento diretto{" "}
                <a
                  href=""
                  className="tooltip"
                  data-title="lorem ipsum dolor sit amet consectetur adipiscing elit"
                >
                  (?)
                </a>
              </p>
              <div className="tooltip-box">
                <span className="the-block">
                  <p className="note"></p>
                </span>
              </div>
            </li>
            <li className="form_nohover">
              <h4>Include</h4>
              <p>Rc Auto</p>
              <p>Assistenza Stradale</p>
              <p>Protezione Satellitare</p>
              <div className="docs mt_10px">
                <h4>Documenti</h4>
                <p>
                  <a href="" className="doc">
                    documento1.pdf
                  </a>{" "}
                  <span className="cta_delete">x elimina</span>
                </p>
              </div>
            </li>
            <li>
              <h4>Prezzo annuo</h4>
              <span className="price">€3000,00</span>

              <a href="" className="cta green cta_small arrow">
                Salva
              </a>
              <a href="" className="cta orange cta_small arrow">
                Vai al sito
              </a>
            </li>
          </ul>
        </div>
        <div className="list_schede_item_actions">
          <a
            href=""
            className="open_detail cta_small ml_30px"
            onClick={(e) => {
              e.preventDefault();
              SetShowDetails(!showDetails);
            }}
          >
            <span className="display_ib">
              <object
                type="image/svg+xml"
                data={Arrow}
                className="icon icon_arrow "
              ></object>
            </span>{" "}
            Maggiori dettagli
          </a>
          <div className="details-container">
          <CSSTransition
           in={showDetails}
           classNames="my-details"
           timeout={300}
          >
           <div className="details">
            <div className="table_container details_container">
              <table>
                <tbody>
                  <tr>
                    <th>Garanzia</th>
                    <th>Massimale</th>
                    <th>Scoperto Franchigia</th>
                    <th className="text_right">Prezzo</th>
                  </tr>
                  <tr>
                    <td>
                      <p>Rc Auto</p>
                    </td>
                    <td>
                      <p>10000000</p>
                    </td>
                    <td>
                      <p>0,00</p>
                    </td>
                    <td className="text_right">
                      <p>175,66 €</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Assistenza stradale</p>
                    </td>
                    <td>
                      <p></p>
                    </td>
                    <td>
                      <p></p>
                    </td>
                    <td className="text_right">
                      <p>18,09 €</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Dispositivo Satellitare</p>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="text_right">
                      <p>Gratis</p>
                    </td>
                  </tr>
                  <tr className="totale">
                    <td>
                      <h3>
                        <strong>Totale</strong>
                      </h3>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="text_orange text_right">
                      <h3>302,11 €</h3>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                Il prezzo è riferito al pagamento tramite
                carta di credito.
              </p>
              <p>
                Puoi scegliere di pagare anche con bonifico
                bancario, chiamando allo 06 45 23 45 23 dal
                Lunedì al Venerdì, dalle 8 alle 21 e il Sabato
                dalle 9 alle 18.
              </p>
              <p>
                ConTe.it si distingue per la sua praticità di
                offerta, basta una telefonata allo 06 83 460
                460 per attivare il Servizio Assistenza
                Sinistri e ridurre al minimo i tempi e le
                modalità di gestione delle pratiche,
                garantendo così la massima tranquillità al
                cliente in caso di sinistro.
              </p>
              <p className="pdf">
                <span className="display_ib">
                  <object
                    type="image/svg+xml"
                    data="images/download.svg"
                    className="icon icon_w10"
                  ></object>
                </span>
                <a target="_blank" href="">
                  Allegato 3 e 4
                </a>
              </p>
              <p className="pdf">
                <span className="display_ib">
                  <object
                    type="image/svg+xml"
                    data="images/download.svg"
                    className="icon icon_w10"
                  ></object>
                </span>
                <a target="_blank" href="">
                  Set informativo
                </a>
              </p>
            </div>
          </div>
    
          </CSSTransition>
          </div>
        </div>
      </div>
    
    );
}