import React, { useState, useEffect } from "react";
import { GetItem } from "../service/session";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FilterArrayByKeyValue } from "../helpers";

export default function DetailsCompletedScreen({  SeoContent, AgentAvatar }) {
	const { data } = GetItem("INS_TYPE");
  const InsuranceType = data;
  let SEO = null;
  if (InsuranceType === "A") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }
  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO.result.title}</title>
          <meta name="title" content={SEO.result.meta_title} />
          <meta name="description" content={SEO.result.meta_description} />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <NavLink to="/bonus-famiglia">
          <section className="col-full title_section text_center">
            <div className="center-block">
              <div className="col-group">
                <div className="col-full">
                  <div className="rform">
                    <div className="avatar">
                      <img src={AgentAvatar} alt="Agent Avatar" />
                    </div>
                    <h1 className="text_orange">
                      Passiamo ora alla situazione assicurativa <br/>attuale.          
                    </h1>
                    <br />
                    <br />
                    <div className="button_container">
                      <button className="cta green ml280">Prosegui</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </NavLink>
      </main>
    </div>
  );
}
