export default function DynamicSelect({name, value, SetValue, label, elements}) {
  return(
      <select
          id={name}
          name={name}
          value={value}
          onChange={({ target: { value } }) => {
            SetValue(value);
          }}
      >
        <option value="">Scegli {label}</option>
        {elements &&
        elements.map((_e) => {
          return (
              <option
                  key={`key_s_${_e.id}`}
                  value={_e.id}
              >
                {_e.value}
              </option>
          );
        })}
      </select>
  )
}
