import React, { useState, useEffect } from "react";
import {
  CreateYearsArray,
  FilterArrayByKeyValue,
  NavigateTo,
} from "../helpers";
import { GetItem, SetItem } from "../service/session";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

export default function IncidentiScreen({ SeoContent, AgentAvatar }) {
  const H = useHistory();
  const { data } = GetItem("INS_TYPE");
  const InsuranceType = data;
  const Years = CreateYearsArray(5);
  let SEO = null;
  if (InsuranceType === "A") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }

  const [isReady, SetIsReady] = useState(false);
  const [Incidenti, SetIncidenti] = useState(false);
  const [ViolationsNumber, SetViolationsNumber] = useState("1");
  const [ViolationsArray, SetViolationsArray] = useState([]);

  const SelectAnswer = ({ target: { value } }) => {
    SetIncidenti(value);
    if (value !== "yes") {
      SetIsReady(true);
    } else {
      SetViolationsNumber("1");
      SetViolationsArray([]);
			SetIsReady(false);
    }
  };

  const goToNextStep = () => {
    SetItem("VIOLATIONS", Incidenti);
		SetItem("VIOLATIONS_NR", ViolationsNumber);
		SetItem("VIOLATIONS_DATA", ViolationsArray);

    NavigateTo({
      path: "seleziona-guida",
      navigator: H,
    });
  };

  const SetOldData = () => {
    let s_incidenti = GetItem("VIOLATIONS");
    // let s_ViolationsNumber = GetItem("VIOLATIONS_NR");
    // let s_ViolationsArray = GetItem("VIOLATIONS_DATA");

    if (s_incidenti.data) {
      // console.log('HERE SETTING DATA');
      // console.log(s_incidenti);
      SetIncidenti(s_incidenti.data);
      SetIsReady(true);
    }

  };

  const CheckIfAllViolationsAreCompleted = () => {
    let VNumber = JSON.parse(ViolationsNumber);
    if (ViolationsArray.length !== VNumber) {
      return;
    }
    for (let i = 0; i < VNumber; i++) {
      if (ViolationsArray[i] === undefined) {
        SetIsReady(false);
        i = VNumber + 5;
        return;
      }
      if (!ViolationsArray[i]) {
        SetIsReady(false);
        i = VNumber + 5;
        return;
      }
      if (!ViolationsArray[i].year || ViolationsArray[i].year === "- Anno -") {
        SetIsReady(false);
        i = VNumber + 5;
        return;
      }
      if (
        !ViolationsArray[i].type ||
        ViolationsArray[i].type === "- Tipo di sinistro -"
      ) {
        SetIsReady(false);
        i = VNumber + 5;
        return;
      }
    }
    SetIsReady(true);
  };

  const RenderViolationsOptions = () => {
    return (
      <fieldset className="no-hover">
        <ul id="violations" className="data_summary">
          {Array.from(Array(JSON.parse(ViolationsNumber)), (item, index) => {
            return (
              <li className="double-select" key={`key_new_${index}`}>
                <span>Sinistri {index + 1}</span>
                <select
                  className="first"
                  onChange={({ target: { value } }) => {
                    ModifyViolation(index, "year", value);
                    CheckIfAllViolationsAreCompleted();
                  }}
                >
                  <option>- Anno -</option>
                  {Years.map((year) => {
                    return (
                      <option value={year} key={`key_year_${year}`}>
                        {year}
                      </option>
                    );
                  })}
                </select>
                <select
                  className="second"
                  onChange={({ target: { value } }) => {
                    ModifyViolation(index, "type", value);
                    CheckIfAllViolationsAreCompleted();
                  }}
                >
                  <option>- Tipo di sinistro -</option>
                  <option value="1">Pagato</option>
                  <option value="2">Riservato dani a persone</option>
                  <option value="3">Riservato dani a cose</option>
                  <option value="4">
                    Pagato con responsabilità principale
                  </option>
                  <option value="5">Pagato con responsabilità paritaria</option>
                  <option value="6">Senza colpa</option>
                </select>
              </li>
            );
          })}
        </ul>
      </fieldset>
    );
  };

  const ModifyViolation = (index, key, value) => {
    let VArray = ViolationsArray;
    if (VArray && VArray.length && VArray[index]) {
      VArray[index][key] = value;
    } else {
      let V = {};
      V[key] = value;
      VArray[index] = V;
    }
    SetViolationsArray(VArray);
  };

	const ResetSelectDropdowns = () => {
		let DropDowns = document.querySelectorAll("#violations select");
	  DropDowns.forEach((dropDown) =>{
			dropDown.selectedIndex = 0; 
		})
	}

  useEffect(() => {
    SetOldData();
  }, []);

  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO && SEO.result.title}</title>
          <meta name="title" content={SEO && SEO.result.meta_title} />
          <meta
            name="description"
            content={SEO && SEO.result.meta_description}
          />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  <fieldset>
                    <label className="label">
                      Hai fatto qualche sinistro negli ultimi 5 anni?
                    </label>
                    <ul className="standard_radio inline">
                      <li>
                        <input
                          type="radio"
                          id="rinnovo"
                          name="rinnovo"
                          value="yes"
                          checked={Incidenti === "yes"}
                          onChange={SelectAnswer}
                        />
                        <label htmlFor="rinnovo">Si</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="nuova_polizza"
                          name="rinnovo"
                          value="no"
                          checked={Incidenti === "no"}
                          onChange={SelectAnswer}
                        />
                        <label htmlFor="nuova_polizza">No</label>
                      </li>
                    </ul>
                  </fieldset>

                  {Incidenti === "yes" && (
                    <>
                      <fieldset>
                        <ul className="data_summary">
                          <li>
                            <span>Sinistri avvenuti neglio ultimi 5 anni</span>
                            <select
                              value={ViolationsNumber}
                              onChange={({ target: { value } }) => {
                                SetViolationsNumber(value);
																SetIsReady(false);
																ResetSelectDropdowns();
																SetViolationsArray([]);
                              }}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                            </select>
                          </li>
                        </ul>
                      </fieldset>

                      {RenderViolationsOptions()}
                    </>
                  )}

                  <div className="button_container">
                    <button
                      style={{
                        opacity: isReady ? 1 : 0.5,
                        transform: isReady ? "scale(1)" : "scale(.9)",
                        pointerEvents: isReady ? "all" : "none",
                      }}
                      className="cta green ml280"
                      onClick={goToNextStep}
                    >
                      Prosegui
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
