import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {GetCities, GetLoacationFromAdress} from "../service";
import GoogleMapReact from "google-map-react";
import configData from "../config.json";
import { GetItem, SetItem } from "../service/session";
import { NavigateTo } from "../helpers";
import loaderSVG from "../assets/images/loader.svg";

const LocationPin = () => (
  <svg
    style={{ height: "36px", marginTop: "-60px" }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path
      fill="#f98721"
      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
    ></path>
  </svg>
);

export default function IndirizzoScreen({ AgentAvatar }) {
  const H = useHistory();
  const location = useLocation();
  const { firstName } = location.state;
  const [address, SetAddress] = useState("");
  const [houseNumber, SetHouseNumber] = useState("");
  const [city, SetCity] = useState("");
  const [province, SetProvince] = useState("");
  const [postalCode, SetPostalCode] = useState("");
  const [timoutForSearch, SetTimoutForSearch] = useState(0);
  const [showMap, SetShowMap] = useState(false);
  const [showLoader, SetShowLoader] = useState(false);
  const [cities, SetCities] = useState(null);
  const [birthPlaceName, SetBirthPlaceName] = useState("");
  const [centerMap, SetCenterMap] = useState({
    lat: 41.9028,
    lng: 12.4964,
  });
  const [isReady, SetIsReady] = useState(false);
  const FirstInput = useRef(null);

  const ShowLocationInMap = (adr) => {
    SetShowMap(false);
    GetLoacationFromAdress({ address: adr })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "OK") {
          if (res.results.length) {
            let Result = res.results[0];
            SetCenterMap(Result.geometry.location);
            setTimeout(() => initMap(), 600);
          }
        }
      });
  };

  const initMap = () => {
    SetShowMap(true);
  };

  const goToNextStep = () => {
    SetItem("U_ADR", address);
    SetItem("U_HNR", houseNumber);
    SetItem("U_CITYNAME", birthPlaceName);
    SetItem("U_CITY", city);
    SetItem("U_PROVINCE", province);
    SetItem("U_POSTAL_CODE", postalCode);
    NavigateTo({
      path: "/nato",
      navigator: H,
    });
  };

  const SetOldData = () => {
    let s_address = GetItem("U_ADR");
    let s_houseNumber = GetItem("U_HNR");
    let s_city = GetItem("U_CITY");
    let s_cityName = GetItem("U_CITYNAME");
    let s_postalCode = GetItem("U_POSTAL_CODE");
    let s_province = GetItem("U_PROVINCE");
    if (s_address.data !== null) {
      SetAddress(s_address.data);
    }
    if (s_houseNumber.data !== null) {
      SetHouseNumber(s_houseNumber.data);
    }
    if (s_city.data !== null) {
      SetCity(s_city.data);
    }
    if (s_cityName.data !== null) {
      SetBirthPlaceName(s_cityName.data);
    }
    if (s_province.data !== null) {
      SetProvince(s_province.data);
    }
    if (s_postalCode.data !== null) {
      SetPostalCode(s_postalCode.data);
    }
  };

  useEffect(() => {
    SetOldData();
    FirstInput.current.focus();
  },[]);

  useEffect(() => {
    const CheckIfReady = () => {
      if (address !== "" && houseNumber !== "" && city !== "" && postalCode !== "") {
        SetIsReady(true);
        if (timoutForSearch) {
          clearTimeout(timoutForSearch);
        }
        const adr = `${address} ${houseNumber}, ${postalCode} ${birthPlaceName}`;
        SetTimoutForSearch(
            setTimeout(() => ShowLocationInMap(adr), 1000)
        );
        return;
      }
      SetIsReady(false);
    };
    CheckIfReady();
  }, [address, houseNumber, city, postalCode]);

  const SearchCities = () => {
    GetCities({ query: birthPlaceName }).then((res) => {
      if (res.statusCode === 200) {
        SetCities(res.data);
        SetShowLoader(false)
      } else {
        SetCities(null);
      }
    });
  };

  const SelectCity = (city) => {
    SetBirthPlaceName(city.comune);
    SetCity(city.cod_istat);
    SetProvince(city.provincia);
    SetPostalCode(city.cap);
    SetCities(null);
  };

  return (
    <div className="page">
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
                <h2 className="text_black">
                  Ciao {firstName ? firstName : ""}, dove sei residente?
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                <fieldset>
                  <input
                    ref={FirstInput}
                    type="text"
                    size="30"
                    name="indirizzo"
                    id="indirizzo"
                    className="input_indirizzo"
                    placeholder="Via Garibaldi"
                    value={address}
                    autoComplete="off"
                    onChange={({ target: { value } }) => {
                      SetAddress(value);
                    }}
                  />
                  <span className="inline_field">
                    <label className="display_ib label label_nr">N.</label>
                    <input
                      type="text"
                      size="3"
                      maxLength="5"
                      name="nr_civico"
                      id="nr_civico"
                      className="input_nr_civico"
                      placeholder="1232"
                      autoComplete="off"
                      value={houseNumber}
                      onChange={({ target: { value } }) =>
                        SetHouseNumber(value)
                      }
                    />
                  </span>
                </fieldset>
                  <fieldset>
                    <span className="input-with-loader">
                      <object className="loader" style={{display: showLoader ? 'block' : 'none'}} title="Loader" type="image/svg+xml" data={loaderSVG} />
                      <input
                          type="text"
                          name="city"
                          id="city"
                          placeholder="Città"
                          autoComplete="off"
                          className="input_indirizzo"
                          value={birthPlaceName}
                          onChange={({ target: { value } }) => {
                            if (timoutForSearch) {
                              clearTimeout(timoutForSearch);
                            }
                            SetBirthPlaceName(value);
                            SetTimoutForSearch(
                                setTimeout(() => {
                                  if (value.length > 0 ) {
                                    SetShowLoader(true)
                                    SearchCities(value)
                                  }
                                }, 1000)
                            );
                          }}
                      />
                    </span>
                      {cities && cities.length !== 0 && (
                          <div className="birth-places birth-places-indirizzo dropdown">
                            <ul>
                              {cities.map((city) => {
                                return (
                                    <li
                                        key={`key_${city.id}`}
                                        onClick={() => SelectCity(city)}
                                    >
                                      {city.comune}
                                      <span> ({city.provincia})</span>
                                    </li>
                                );
                              })}
                            </ul>
                          </div>
                      )}
                    <span className="inline_field inline_address">
                    <input
                        type="text"
                        size="3"
                        maxLength="5"
                        name="postalCode"
                        id="postalCode"
                        className="input_nr_civico"
                        placeholder="CAP"
                        autoComplete="off"
                        value={postalCode}
                        onChange={({ target: { value } }) =>
                            SetPostalCode(value)
                        }
                    />
                  </span>
                </fieldset>
                {showMap ? (
                  <GoogleMapReact
                    style={{
                      width: "366px",
                      height: "150px",
                      position: "relative",
                      margin: "auto",
                      borderRadius: "4px",
                      border: "1px solid #dedede",
                      overflow: "hidden",
                    }}
                    bootstrapURLKeys={{ key: configData.GoogleMapsApiKey }}
                    defaultCenter={centerMap}
                    defaultZoom={16}
                    options={{
                      disableDefaultUI: true,
                      zoomControl: false,
                      fullscreenControl: false,
                      draggable: false,
                      gestureHandling: "none",
                      draggableCursor: "arrow",
                    }}
                  >
                    <LocationPin lat={centerMap.lat} lng={centerMap.lng} />
                  </GoogleMapReact>
                ) : null}
              </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="button_container">
                <button
                  style={{
                    opacity: isReady ? 1 : 0.5,
                    transform: isReady ? "scale(1)" : "scale(.9)",
                    pointerEvents: isReady ? "all" : "none",
                  }}
                  className="cta green ml280"
                  onClick={goToNextStep}
                >
                  Prosegui
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
