import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";
import {
  CreateYearsArray,
  FilterArrayByKeyValue,
  NavigateTo,
} from "../helpers";
import { GetItem, SetItem } from "../service/session";
import loaderSVG from "../assets/images/loader-white.svg";
import { GetFullInfoCar } from "../service";

export default function InsuranceAutoDetailsScreen({
  SeoContent,
  AgentAvatar,
}) {
  const H = useHistory();
  const YEARS = CreateYearsArray();
  const { slug, type } = useParams();
  const SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", slug);
  const [noPlate, SetNoPlate] = useState(false);
  const [carPlate, SetCarPlate] = useState("");
  const [purchasedYear, SetPurchasedYear] = useState("");
  const [isReady, SetIsReady] = useState(false);
  const [isLaoding, SetIsLoading] = useState(false);
  const FirstInput = useRef(null);

  const goToNextStep = () => {
    SetItem("CAR_P", carPlate);
    SetItem("CAR_NP", noPlate);
    SetItem("CAR_P_Y", purchasedYear);
    if (noPlate) {
      H.replace("/assicurazione", {
        message:
          "Portuppo l'assenza della targa preguidica la corretta preventivazione. <br/> E' possibile elaborare un preventivo di massima ma sarebbe puramente indicativo, con un margine di errore anche del 100%.<br/> Ti consigliamo, quindi, di acquistare il veicolo e provare la quotazione.<br/> Nele Frattempo ti consigliamo di controllare se hai altre richieste assicurative da poterci sottoporre.",
      });
      return;
    }
    NavigateTo({
      path: "/veicolo",
      navigator: H,
    });
  };

  const LoadCarInfoAndGoToNextStep = async () => {
    SetIsLoading(true);
    await GetFullInfoCar({ plate: carPlate }).then((res) => {
      if(res.statusCode === 200) {
        SetItem("CAR_INFO", res.data.data);
        goToNextStep();
      } else {
        SetItem("CAR_INFO", null);
        goToNextStep();
      }
      SetIsLoading(false);
    });
  };

  
  useEffect(() => {
    const SetOldData = () => {
      let s_carPlate = GetItem("CAR_P");
      let s_noPlate = GetItem("CAR_NP");
      let s_purchasedYear = GetItem("CAR_P_Y");
      SetNoPlate(JSON.parse(s_noPlate.data));
      SetCarPlate(s_carPlate.data);
      SetPurchasedYear(s_purchasedYear.data);
    };
    SetOldData();
    FirstInput.current.focus();
  },[]);


  useEffect(() => {
    const CheckIfReady = () => {
      if (type === "nuova") {
        if (carPlate !== "") {
          SetIsReady(true);
          return;
        }
        if (noPlate) {
          SetIsReady(true);
          return;
        }
      }
      if (carPlate !== "" && purchasedYear !== "") {
        SetIsReady(true);
        return;
      }
      SetIsReady(false);
      return;
    };
    CheckIfReady();
  }, [noPlate, carPlate]);

  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO.result.title}</title>
          <meta name="title" content={SEO.result.meta_title} />
          <meta name="description" content={SEO.result.meta_description} />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  {type !== "nuova" ? (
                    <>
                      <fieldset>
                        <label className="label">
                          Inserisci adesso la targa del veicolo
                        </label>
                        <input
                          ref={FirstInput}
                          type="text"
                          size="30"
                          name="targa"
                          id="targa"
                          placeholder="Scrivi targa veicolo"
                          autoComplete="off"
                          value={carPlate}
                          onChange={({ target: { value } }) => {
                            SetNoPlate(false);
                            SetCarPlate(value);
                          }}
                        />
                      </fieldset>

                      <fieldset>
                        <label className="label">
                          In che hanno hai acquistato il veicolo ?
                        </label>
                        <select
                          id="civilstatus"
                          name="civilstatus"
                          value={purchasedYear}
                          onChange={({ target: { value } }) => {
                            SetPurchasedYear(value);
                          }}
                        >
                          {YEARS.map((year) => (
                            <option key={`key_${year}`} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </fieldset>
                    </>
                  ) : (
                    <>
                      <fieldset>
                        <label className="label">
                          Inserisci adesso la targa del veicolo
                        </label>
                        <input
                          ref={FirstInput}
                          type="text"
                          size="30"
                          name="targa"
                          id="targa"
                          placeholder="Scrivi targa veicolo"
                          autoComplete="off"
                          value={carPlate}
                          onChange={({ target: { value } }) => {
                            SetNoPlate(false);
                            SetCarPlate(value);
                          }}
                        />
                        <div className="col-full text_center">
                          <label className="label label_nr">
                            <input
                              type="checkbox"
                              name="value"
                              id="value"
                              checked={noPlate}
                              // defaultChecked={noPlate}
                              onChange={({ target: { checked } }) => {
                                SetCarPlate("");
                                SetNoPlate(checked);
                              }}
                            />{" "}
                            non ho la targa
                          </label>
                        </div>
                      </fieldset>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="button_container">
                {!isLaoding ? (
                  <button
                    style={{
                      opacity: isReady ? 1 : 0.5,
                      transform: isReady ? "scale(1)" : "scale(.9)",
                      pointerEvents: isReady ? "all" : "none",
                    }}
                    className="cta green ml280"
                    onClick={LoadCarInfoAndGoToNextStep}
                  >
                    Prosegui
                  </button>
                ) : (
                  <button
                    className="cta green ml280"
                  >
                    <object
                      id="loader"
                      type="image/svg+xml"
                      data={loaderSVG}
                    />{" "}
                    Prosegui
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
