import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { FilterArrayByKeyValue, NavigateTo } from "../helpers";
import { GetItem, SetItem } from "../service/session";

export default function ReuseMeritClassScreen({ AgentAvatar, SeoContent }) {
  const H = useHistory();
  const { data } = GetItem("INS_TYPE");
  const InsuranceType = data;
  let SEO = null;
  if (InsuranceType === "A") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }

  const [isReady, SetIsReady] = useState(false);
  const [reuseMerit, SetReuseMerit] = useState(false);
  const [meritClass, SetMeritClass] = useState("");
  const [targa, SetTarga] = useState("");

  const SelectAnswer = ({ target: { value } }) => {
    SetReuseMerit(value);
    if (value !== "yes_my_car") {
      SetIsReady(true);
      SetMeritClass("");
      SetTarga("");
    } else {
      SetIsReady(false);
    }
  };

  const goToNextStep = () => {
    SetItem("REUSE_MERIT", reuseMerit);
    SetItem("REUSE_MERIT_CLASS", meritClass);
    SetItem("REUSE_MERIT_TARGA", targa);
    if( reuseMerit === "yes_my_car"){
      return NavigateTo({
        path: "/seleziona-guida",
        navigator: H,
      });
    }
    NavigateTo({
        path: "/dati-veicolo-familiare",
        navigator: H,
    });
  };

  const SetOldData = () => {
    let s_reuseMerit = GetItem("REUSE_MERIT");
    let s_meritClass = GetItem("REUSE_MERIT_CLASS");
    let s_targa = GetItem("REUSE_MERIT_TARGA");

    if (s_reuseMerit.data) {
      SetReuseMerit(s_reuseMerit.data);
    }
    if (s_meritClass.data) {
      SetMeritClass(s_meritClass.data);
    }
    if (s_targa.data) {
      SetTarga(s_targa.data);
    }
  };

  useEffect(() => {
    SetOldData();
  }, []);

  useEffect(() => {
    const checkIfIsReady = () => {
      if (targa !== "" && meritClass !== "") {
        SetIsReady(true);
      }
    };
    checkIfIsReady();
  }, [targa, meritClass]);

  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO && SEO.result.title}</title>
          <meta name="title" content={SEO && SEO.result.meta_title} />
          <meta
            name="description"
            content={SEO && SEO.result.meta_description}
          />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  <fieldset>
                    <label className="label">
                      Puoi utilizare la classe di merito di un veicolo già assicurato ?
                    </label>

                    <ul className="standard_radio inline">
                      <li>
                        <input
                          type="radio"
                          id="no_gancio"
                          name="gancio"
                          value="yes_my_car"
                          checked={reuseMerit === "yes_my_car"}
                          onChange={SelectAnswer}
                        />
                        <label htmlFor="no_gancio">Si, il veicolo è mio</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="yes_gancio"
                          name="gancio"
                          value="yes_not_my_car"
                          checked={reuseMerit === "yes_not_my_car"}
                          onChange={SelectAnswer}
                        />
                        <label htmlFor="yes_gancio">
                          Si, il veicolo è di un familiare/convivente
                        </label>
                      </li>
                    </ul>
                  </fieldset>

                  {reuseMerit === "yes_my_car" && (
                    <>
                      <fieldset>
                        <label className="label">
                          Targa del veicolo di cui utilizzi la classe di merito
                        </label>
                        <input
                          type="text"
                          placeholder="Scrivi targa veicolo"
                          autoComplete="off"
                          value={targa}
                          onChange={({ target: { value } }) => {
                            SetTarga(value);
                          }}
                        />
                      </fieldset>

                      <fieldset>
                        <label className="label">
                          Classe di merito CU del veicolo già assicurato
                        </label>
                        <input
                          type="number"
                          min="1"
                          max="14"
                          step="1"
                          placeholder="Valore numero 1-14"
                          autoComplete="off"
                          value={meritClass}
                          onChange={({ target: { value } }) => {
                            SetMeritClass(value);
                          }}
                        />
                      </fieldset>
                    </>
                  )}

                  <a className="" href="#" target="_blank">
                    <fieldset>
                      <span style={{ color: "red" }}>
                        Vuoi approfondire meglio?
                      </span>
                    </fieldset>
                  </a>

                  <div className="button_container">
                    <button
                      style={{
                        opacity: isReady ? 1 : 0.5,
                        transform: isReady ? "scale(1)" : "scale(.9)",
                        pointerEvents: isReady ? "all" : "none",
                      }}
                      className="cta green ml280"
                      onClick={goToNextStep}
                    >
                      Prosegui
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
