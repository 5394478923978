import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Switch, Route, useLocation, Link } from "react-router-dom";
import ProprietarioScreen from "./screens/ProprietarioScreen";
import StartScreen from "./screens/StartScreen";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ComparaSvg from "./assets/images/compara.svg";
import AgentAvatar from "./assets/images/foto150x150.jpg";
import IndirizzoScreen from "./screens/IndirizzoScreen";
import { Helmet } from "react-helmet";
import { GetCoBrand, GetListPages } from "./service";
import BirthPlaceDateScreen from "./screens/BirthPlaceDateScreen";
import InfoScreen from "./screens/InfoScreen";
import SelectInsuranceScreen from "./screens/SelectInsuranceScreen";
import SelectIfNewScreen from "./screens/SelectIfNewScreen";
import InsuranceAutoDetailsScreen from "./screens/InsuranceAutoDetailsScreen";
import CarDetailsScreen from "./screens/CarDetailsScreen";
import OtherDetailsScreen from "./screens/OtherDetailsScreen";
import DetailsCompletedScreen from "./screens/DetailsCompletedScreen";
import FamilyBonusScreen from "./screens/FamilyBonusScreen";
import MeritClassScreen from "./screens/MeritClassScreen";
import IncidentiScreen from "./screens/IncidentiScreen";
import SelectGuideScreen from "./screens/SelectGuideScreen";
import TowHookScreen from "./screens/TowHookScreen";
import LimitationsScreen from "./screens/LimitationsScreen";
import DirectCompensationScreen from "./screens/DirectCompensationScreen";
import PreventivoScreen from "./screens/PreventivoScreen";
import SelectFacilitiesScreen from "./screens/SelectFacilitiesScreen";
import ReuseMeritClassScreen from "./screens/ReuseMeritClassScreen";
import RelativesCarDetailsScreen from "./screens/RelativesCarDetailsScreen";
import CustomContent from "./screens/components/CustomContent";
import { RemoveItem, SetItem } from "./service/session";
import ProgressBar from "./screens/components/ProgressBar";
import PrivacyContacts from "./screens/PrivacyContacts";

function App() {
  let location = useLocation();

  const [SeoPages, SetSeoPages] = useState(null);
  const [trackingCodeGA, SetTrackingCodeGA] = useState("");
  const [pathname, SetPathname] = useState("");
  const [customHeader, SetCustomHeader] = useState("");
  const [customFooter, SetCustomFooter] = useState("");
  const [showHeader, SetShowHeader] = useState(true);
  const [showCustomHeader, SetShowCustomHeader] = useState(false);
  const [showCustomFooter, SetShowCustomFooter] = useState(false);
  const [marginTop, SetMarginTop] = useState(0);

  const LoadAppSeo = () => {
    GetListPages().then((res) => {
      if (res.statusCode === 200) {
        SetSeoPages(res.data);
      }
    });
  };

  useEffect(() => {
    if (trackingCodeGA.length > 0) {
      ReactGA.initialize(trackingCodeGA);
      ReactGA.pageview(location.pathname + location.search);
    }
    if (!SeoPages) {
      LoadAppSeo();
    }
    SetPathname(location.pathname);
    SetMarginTop(
      document.getElementsByClassName("rform")[0].clientHeight + 580
    );
  }, [location, SeoPages]);

  useEffect(() => {
    const hostname = window.location.hostname;
    GetCoBrand({ hostname }).then((res) => {
      if (res.statusCode === 200) {
        if (
          res.data.google_analytics_code &&
          res.data.google_analytics_code.length > 0
        ) {
          SetTrackingCodeGA(res.data.google_analytics_code);
        }
        if (res.data.id) {
          SetItem("CO_BRAND", res.data.id);
        }
        if (res.data.header && res.data.header.length > 0) {
          const header = new Buffer(res.data.header, "base64").toString(
            "ascii"
          );
          SetCustomHeader(
            React.createElement("p", {
              key: "header",
              dangerouslySetInnerHTML: { __html: header },
            })
          );
          SetShowCustomHeader(true);
          SetShowHeader(false);
        }
        if (res.data.footer && res.data.footer.length > 0) {
          const footer = new Buffer(res.data.footer, "base64").toString(
            "ascii"
          );
          SetCustomFooter(
            React.createElement("p", {
              key: "footer",
              dangerouslySetInnerHTML: { __html: footer },
            })
          );
          SetShowCustomFooter(true);
        }
      } else {
        RemoveItem("CO_BRAND");
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  scrollToTop();
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Preventivo assicurazione auto e moto in 3 minuti</title>
      </Helmet>
      <header className={`col-full ${!showHeader ? "whitelabel" : ""}`}>
        <CustomContent
          content={customHeader}
          display={showCustomHeader}
          className={"top-header"}
        />
        <ProgressBar pathname={pathname} />
        <div
          className="header_container"
          style={{ display: showHeader ? "block " : "none" }}
        >
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="col-content">
                  <Link to="/" className="logo float_left">
                    <span className="display_ib">
                      <object
                        type="image/svg+xml"
                        data={ComparaSvg}
                        aria-label="app logo"
                      />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={300} classNames="fade">
              <Switch location={location}>
                <Route exact path="/">
                  <StartScreen />
                </Route>

                <Route path="/proprietario">
                  <ProprietarioScreen AgentAvatar={AgentAvatar} />
                </Route>

                <Route path="/indirizzo">
                  <IndirizzoScreen AgentAvatar={AgentAvatar} />
                </Route>

                <Route path="/nato">
                  <BirthPlaceDateScreen AgentAvatar={AgentAvatar} />
                </Route>

                <Route path="/informazioni">
                  <InfoScreen AgentAvatar={AgentAvatar} />
                </Route>

                <Route path="/privacy">
                  <PrivacyContacts AgentAvatar={AgentAvatar} />
                </Route>

                <Route path="/assicurazione">
                  <SelectInsuranceScreen AgentAvatar={AgentAvatar} />
                </Route>

                <Route path="/polizza/:slug">
                  <SelectIfNewScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/tipo-di-polizza/:type/:slug">
                  <InsuranceAutoDetailsScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/veicolo">
                  <CarDetailsScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/confermare-dettagli">
                  <OtherDetailsScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/dettagli-completati">
                  <DetailsCompletedScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/bonus-famiglia">
                  <FamilyBonusScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/merito">
                  <MeritClassScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/incidenti">
                  <IncidentiScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/seleziona-guida">
                  <SelectGuideScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/gancio-di-traino">
                  <TowHookScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/limitazioni-alle-rivalse">
                  <LimitationsScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/risarcimento-diretto">
                  <DirectCompensationScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/agevolazione">
                  <SelectFacilitiesScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/utilizzare-merito">
                  <ReuseMeritClassScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/dati-veicolo-familiare">
                  <RelativesCarDetailsScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>

                <Route path="/preventivo">
                  <PreventivoScreen
                    AgentAvatar={AgentAvatar}
                    SeoContent={SeoPages}
                  />
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
      <CustomContent
        content={customFooter}
        display={showCustomFooter}
        className={"footer"}
        marginTop={marginTop}
      />
    </div>
  );
}

export default App;
