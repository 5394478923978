import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { NavigateTo } from "../helpers";
import { GetItem, SetItem } from "../service/session";

export default function ProprietarioScreen({ AgentAvatar }) {
  const [FirstName, SetFirstName] = useState("");
  const [LastName, SetLastName] = useState("");
  const [contTipo, SetConTipo] = useState("");
  const [isReady, setIsReady] = useState(false);
  const H = useHistory();
  const FirstInput = useRef(null);

  const SetOldData = () => {
    let s_FirstName = GetItem("U_NAME");
    let s_LastName = GetItem("U_LSNAME");
    let s_contTipo = GetItem("U_CONT_TIPO");
    if (s_FirstName.data !== null) {
      SetFirstName(s_FirstName.data);
    }
    if (s_LastName.data !== null) {
      SetLastName(s_LastName.data);
    }
    if (s_contTipo.data !== null) {
      SetConTipo(s_contTipo.data);
    }
  };
  
  useEffect(() => {
    SetOldData();
    FirstInput.current.focus();
  },[]);

  useEffect(() => {
    const CheckIfReady = () => {
      if (FirstName !== "" && LastName !== "") {
        setIsReady(true);
        return;
      }
      setIsReady(false);
      return;
    };
    CheckIfReady();
  }, [FirstName, LastName]);

  const goToNextStep = () => {
    SetItem("U_NAME", FirstName);
    SetItem("U_LSNAME", LastName);
    SetItem("U_CONT_TIPO", contTipo);
    NavigateTo({
      path: "/indirizzo",
      params: { firstName: FirstName, lastName: LastName },
      navigator: H
    });
  };

  return (
    <div className="page">
      <div className="fade-cover" />
      <main className="col-full">

        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
                <h2 className="text_black">
                  Facciamo conoscenza, come ti chiami?
                </h2>
              </div>
            </div>
          </div>
        </section>


        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
            <div className="col-full">
                <div className="rform">
                <fieldset>
                  <input
                    ref={FirstInput}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    tabindex="1"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Nome"
                    im-insert="true"
                    value={FirstName}
                    onChange={({ target: { value } }) => SetFirstName(value)}
                  />
                </fieldset>
                <fieldset>
                  <input
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    tabindex="1"
                    type="text"
                    id="surname"
                    name="surname"
                    placeholder="Cognome"
                    im-insert="true"
                    value={LastName}
                    onChange={({ target: { value } }) => SetLastName(value)}
                  />
                </fieldset>
                  <fieldset>
                    <select
                        value={contTipo}
                        onChange={({ target: { value } }) => {
                          SetConTipo(value);
                        }}
                    >
                      <option value="M">Uomo</option>
                      <option value="F">Donna</option>
                    </select>
                  </fieldset>

                <div className="button_container">
                  <button
                    style={{
                      opacity: isReady ? 1 : 0.5,
                      transform: isReady ? "scale(1)" : "scale(.9)",
                      pointerEvents: isReady ? "all" : "none",
                    }}
                    className="cta green ml280"
                    onClick={goToNextStep}
                  >
                    Prosegui
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
