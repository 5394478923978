import React, { useState, useEffect } from "react";
import {
  FilterArrayByKeyValue,
  MakeKilometersArray,
  NavigateTo,
} from "../helpers";
import { GetItem, SetItem } from "../service/session";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

export default function OtherDetailsScreen({ SeoContent, AgentAvatar }) {

  const H = useHistory();
  const TheftProtections = JSON.parse(GetItem("THEFT_PROTECTIONS").data);
  const CarUses = JSON.parse(GetItem("CAR_USES").data);
  const CarParkings = JSON.parse(GetItem("CAR_PARKINGS").data);
  const Kilometers = MakeKilometersArray({
    min: 10000,
    max: 150000,
    step: 10000
  });

  const { data } = GetItem("INS_TYPE");
  const InsuranceType = data;
  let SEO = null;
  let INSTYPE = null;
  if (InsuranceType === "A") {
    INSTYPE = "auto";
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    INSTYPE = "moto";
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    INSTYPE = "autocarro";
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }

  const [OtherAlimentazione, setOtherAlimentazione] = useState("");
  const [Parking, setParking] = useState("");
  const [Antitheft, setAntiTheft] = useState("");
  const [Usage, setUsage] = useState("");
  const [UsageKilometers, setUsageKilometers] = useState("");
  const [FamilyMembers, setFamilyMembers] = useState("");

  const goToNextStep = () => {

    let PolicyType = GetItem("INS_PZ_TYPE");

    SetItem("CAR_OTHER_ALIM",OtherAlimentazione);
    SetItem("CAR_PARKING",Parking);
    SetItem("CAR_ANTITHEFT",Antitheft);
    SetItem("CAR_USAGE",Usage);
    SetItem("CAR_USAGE_KILOMETERS",UsageKilometers);
    SetItem("CAR_FAMILY_MEMBERS",FamilyMembers);

    if(PolicyType.data === "R"){
      NavigateTo({
        path: "/dettagli-completati",
        navigator: H,
      });
    }else{
      NavigateTo({
        path: "/agevolazione",
        navigator: H
      });
    }
  };

  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO.result.title}</title>
          <meta name="title" content={SEO.result.meta_title} />
          <meta name="description" content={SEO.result.meta_description} />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  <fieldset className="no-hover">
                    <label className="label">
                      Adesso ti chiedo di confermare o modificare i seguenti
                      dettagli:
                    </label>
                    <ul className="data_summary">
                      <li>
                        <span>Altra alimentazione:</span>
                        <select
                          value={OtherAlimentazione}
                          onChange={({ target: { value } }) => {
                            setOtherAlimentazione(value);
                          }}
                        >
                          <option value="Nesuna">Nessuna</option>
                          <option value="GPL">GPL</option>
                          <option value="Metano">Metano</option>
                        </select>
                      </li>

                      <li>
                        <span>Parcheggio dell'auto:</span>
                        <select
                          value={Parking}
                          onChange={({ target: { value } }) => {
                            setParking(value);
                          }}
                        >
                          {CarParkings.map((parking) => {
                            return (
                              <option
                                key={`key_p_${parking.id}`}
                                value={parking.id}
                              >
                                {parking.value}
                              </option>
                            );
                          })}
                        </select>
                      </li>
                      {TheftProtections && (
                        <li>
                          <span>Antifurfo:</span>
                          <select
                            value={Antitheft}
                            onChange={({ target: { value } }) => {
                              setAntiTheft(value);
                            }}
                          >
                            {TheftProtections.map((protection) => {
                              return (
                                <option
                                  key={`key_p_${protection.id}`}
                                  value={protection.id}
                                >
                                  {protection.value}
                                </option>
                              );
                            })}
                          </select>
                        </li>
                      )}
                      <li>
                        <span>Utilizzo:</span>
                        <select
                          value={Usage}
                          onChange={({ target: { value } }) => {
                            setUsage(value);
                          }}
                        >
                          {CarUses.map((use) => {
                            return (
                              <option key={`key_p_${use.id}`} value={use.id}>
                                {use.value}
                              </option>
                            );
                          })}
                        </select>
                      </li>

                      {Kilometers && (
                        <li>
                          <span>Km percosi in un anno:</span>
                          <select
                            value={UsageKilometers}
                            onChange={({ target: { value } }) => {
                              setUsageKilometers(value);
                            }}
                          >
                            {Kilometers.map((km) => {
                              return <option value={km}>{km}</option>;
                            })}
                          </select>
                        </li>
                      )}
                      <li>
                        <span>Veicoli posseduti dal nucleo familiare:</span>
                        <select
                          value={FamilyMembers}
                          onChange={({ target: { value } }) => {
                            setFamilyMembers(value);
                          }}
                        >
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="button_container">
                <button className="cta green ml280" onClick={goToNextStep}>
                  Prosegui
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
