import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { FilterArrayByKeyValue, NavigateTo } from "../helpers";
import { GetItem, SetItem } from "../service/session";

export default function SelectIfNewScreen({ AgentAvatar, SeoContent }) {
	const {slug} = useParams();
  const SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", slug );
  const H = useHistory();
  const [isReady, SetIsReady] = useState(false);
  const [polizzaType, SetPolizzaType] = useState(false);

  const SelectPolizzaAndActivateNext = ({ target: { value } }) => {
    SetPolizzaType(value);
    SetIsReady(true);
  };

  const goToNextStep = () => {
    SetItem("INS_PZ_TYPE", polizzaType);
    if (polizzaType === "R") {
      NavigateTo({
        path: `/tipo-di-polizza/rinnovo/${slug}`,
        navigator: H,
      });
    } else {
      NavigateTo({
        path: `/tipo-di-polizza/nuova/${slug}`,
        navigator: H,
      });
    }
  };

  const SetOldData = () => {
    let s_polizzaType = GetItem("INS_PZ_TYPE");
    if (s_polizzaType.data) {
      SetPolizzaType(s_polizzaType.data);
      SetIsReady(true);
    }
  };

  useEffect(() => {
    SetOldData();
  }, []);

  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO && SEO.result.title}</title>
          <meta name="title" content={SEO && SEO.result.meta_title} />
          <meta
            name="description"
            content={SEO && SEO.result.meta_description}
          />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
            <div className="col-full">
                <div className="rform">
              <fieldset>
                <label className="label">

                    Fantastico!
                    <br /> hai appena comprato un veicolo o devi rinnovare la
                    tua polizza ?

                </label>

                <ul className="standard_radio inline">
                  <li>
                    <input
                      type="radio"
                      id="rinnovo"
                      name="rinnovo"
                      value="R"
                      checked={polizzaType === "R"}
                      onChange={SelectPolizzaAndActivateNext}
                    />
                    <label htmlFor="rinnovo">Rinnovo</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="nuova_polizza"
                      name="rinnovo"
                      value="N"
                      checked={polizzaType === "N"}
                      onChange={SelectPolizzaAndActivateNext}
                    />
                    <label htmlFor="nuova_polizza">Nuova Polizza</label>
                  </li>
                </ul>
              </fieldset>
            </div>
            </div>
          </div>
          </div>
        </section>

        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="button_container">
                <button
                  style={{
                    opacity: isReady ? 1 : 0.5,
                    transform: isReady ? "scale(1)" : "scale(.9)",
                    pointerEvents: isReady ? "all" : "none",
                  }}
                  className="cta green ml280"
                  onClick={goToNextStep}
                >
                  Prosegui
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
