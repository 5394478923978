import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { NavigateTo } from "../helpers";
import { GetItem, SetItem } from "../service/session";

export default function PrivacyContacts({ AgentAvatar }) {
  const H = useHistory();
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [info_1, SetInfo1] = useState(false);
  const [info_2, SetInfo2] = useState(false);
  const [info_3, SetInfo3] = useState(true);
  const [info_4, SetInfo4] = useState(true);
  const [info_5, SetInfo5] = useState(true);
  const [info_10, SetInfo10] = useState(true);
  const [info_service, SetInfoService] = useState(true);
  const [info_marketing, SetInfoMarketing] = useState(false);
  const [info_sales, SetInfoSales] = useState(false);
  const [info_profiling, SetInfoProfiling] = useState(false);
  const [isReady, SetIsReady] = useState(false);
  const [errors, SetErrors] = useState({
    email: '0',
    phone: '0',
    info_2: '0',
  });
  const FirstInput = useRef(null);

  const goToNextStep = () => {
    SetItem("U_PHONE", phone);
    SetItem("U_EMAIL", email);
    SetItem("P_INFO1", info_1);
    SetItem("P_INFO2", info_2);
    SetItem("P_INFO3", info_3);
    SetItem("P_INFO4", info_4);
    SetItem("P_INFO5", info_5);
    SetItem("P_INFO10", info_10);
    SetItem("P_INFO_SERVICE", info_service);
    SetItem("P_INFO_MARKETING", info_marketing);
    SetItem("P_INFO_SALES", info_sales);
    SetItem("P_INFO_PROFILING", info_profiling);

    checkValidation('email', email, {regex: /^\S+@\S+$/i});
    checkValidation('phone', phone, {regex: /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[6-90]|36[680]|33[3-90]|32[0-90]|37[0-90]|33[0-90]|39[2-90])\d{7}$/});
    checkValidation('info_2', info_2, {checked: true});

    let execute = true;
    for(const item in errors) {
      if (errors[item] === '1') {
        execute = false;
      }
    }
    if (execute) {
      NavigateTo({
        path: "/assicurazione",
        navigator: H,
      });
    }
  };

  useEffect(() => {
    const SetOldData = () => {
      let s_phone = GetItem("U_PHONE");
      let s_email = GetItem("U_EMAIL");
      let s_info_1 = GetItem("P_INFO1");
      let s_info_2 = GetItem("P_INFO2");
      let s_info_marketing = GetItem("P_INFO_MARKETING");
      let s_info_sales = GetItem("P_INFO_SALES");
      let s_info_profiling = GetItem("P_INFO_PROFILING");
      if (s_phone.data) {
        SetPhone(s_phone.data);
      }
      if (s_email.data !== null) {
        SetEmail(s_email.data);
      }
      if (s_info_1.data !== null) {
        SetInfo1(s_info_1.data);
      }
      if (s_info_2.data !== null) {
        console.log(s_info_2.data)
        SetInfo2(s_info_2.data);
      }
      if (s_info_marketing.data !== null) {
        SetInfoMarketing(s_info_marketing.data);
      }
      if (s_info_sales.data !== null) {
        SetInfoSales(s_info_sales.data);
      }
      if (s_info_profiling.data !== null) {
        SetInfoProfiling(s_info_profiling.data);
      }
    };
    FirstInput.current.focus();
    SetOldData();
  }, []);

  useEffect(() => {
    const CheckIfReady = () => {
      if (email !== "" && phone !== "" && info_1 && info_2) {
        SetIsReady(true);
        return;
      }
      SetIsReady(false);
      return;
    };
    CheckIfReady();
  }, [
    email,
    phone,
    info_1,
    info_2,
    info_profiling,
    info_sales,
    info_marketing,
  ]);

  const checkValidation = (name, value, validate) => {
    if (validate.regex && validate.regex.test(value) === false) {
      SetIsReady(false);
      errors[name] = '1';
      return false;
    } else if(validate.checked && value === 0) {
      SetIsReady(false);
      errors[name] = '1';
      return false;
    } else {
      errors[name] = '0';
    }
    SetErrors(errors)
    return true;
  }
  return (
    <div className="page">
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
                <h2 className="text_black">
                  La tua privacy è importante per noi,  <br />
                  ti chiediamo di specificare come vuoi che vengano gestiti i tuoi dati personali.
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
            <div className="col-full">
              <div className="rform">
                <fieldset className={errors.email === '1' && "wrong"}>
                  <input
                      ref={FirstInput}
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={({ target: { value } }) => SetEmail(value)}
                  />
                </fieldset>
                <fieldset className={errors.phone === '1' && "wrong"}>
                  <input
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Telefono"
                      value={phone}
                      onChange={({ target: { value } }) => SetPhone(value)}
                  />
                </fieldset>
                <fieldset>
                  <label className={`label`}>Privacy</label>
                  <ul className="standard_check inline privacy_container">
                    <li>
                      <input
                          type="checkbox"
                          name="info_2"
                          id="info_2"
                          value={info_2}
                          defaultChecked={info_2}
                          checked={info_2}
                          onChange={({ target: { checked } }) => {
                            SetInfo2(checked);
                          }}
                      />
                      <label htmlFor="info_2"  className={errors.info_2 === '1' && "wrong"}>
                        Dichiaro di voler ricevere per email, in ottemperanza all'articolo 56 del Regolamento 40 IVASS del 02/08/2018, i preventivi personalizzati da parte delle compagnie assicurative partner di Comparasemplice Broker s.r.l. La modalità sarà modificabile una volta entrati in contatto con la compagnia prescelta. Dichiaro di aver preso visione delle note info_rmative relative ai prodotti assicurativi raggiungibili  <a rel="noreferrer" href="https://www.comparasemplice.it/documenti-info_rmativi" target="_blank">qui</a>. Dichiaro di aver preso visione ed accetto l’<a href="https://s3-eu-west-1.amazonaws.com/comparasemplice-assicurazioni/documenti/7A7B.pdf" rel="noreferrer" target="_blank">info_rmativa Precontrattuale</a> (pdf) di cui alla normativa vigente ai sensi del D.Lgs 209/2005 (obbligatorio).
                      </label>
                    </li>
                    <li>
                      <input
                          type="checkbox"
                          name="info_1"
                          id="info_1"
                          value={info_1}
                          defaultChecked={info_1}
                          checked={info_1}
                          onChange={({ target: { checked } }) => {
                            SetInfo1(checked);
                          }}
                      />
                      <label htmlFor="info_1" >
                        Letta l'<a href="https://s3-eu-west-1.amazonaws.com/storage.appbroker/Informativa.pdf" rel="noreferrer" target="_blank">informativa privacy</a>,
                        prendo atto che il trattamento dei miei dati è propedeutico e necessario per consentire l’erogazione dei servizi descritti agli artt. 2.1 e 2.2 del paragrafo 2
                        FINALITÀ E BASI GIURIDICHE DEL TRATTAMENTO (art. 6, paragrafo 1, lettere b) e c) del Regolamento Privacy) e da me richiesti e di essere stato informato che potrà, in ogni tempo, sia opporsi a ciascuno dei trattamenti sotto elencati, sia modificare i consensi espressi in precedenza, anche potendo scegliere con quali mezzi essere contattato - es. email, sms, mms, fax, messaggi su social networks, telefono con operatore o posta cartacea.
                      </label>
                    </li>


                    <li>
                      <input
                          type="checkbox"
                          name="info_marketing"
                          id="info_marketing"
                          value={info_marketing}
                          defaultChecked={info_marketing}
                          checked={info_marketing}
                          onChange={({ target: { checked } }) => {
                            SetInfoMarketing(checked);
                          }}
                      />
                      <label htmlFor="info_marketing" >Acconsento, altresì, al trattamento dei miei dati per finalità di marketing diretto, così come previsto all’art. 2.5 dell’info_rmativa. </label>
                    </li>

                    <li>

                      <input
                          type="checkbox"
                          name="info_sales"
                          id="info_sales"
                          value={info_sales}
                          defaultChecked={info_sales}
                          checked={info_sales}
                          onChange={({ target: { checked } }) => {
                            SetInfoSales(checked);
                          }}
                      />
                      <label htmlFor="info_sales" >Acconsento alla cessione a terzi per finalità di marketing di cui all’art. 2.6 dell’info_rmativa.</label>
                    </li>

                    <li>

                      <input
                          type="checkbox"
                          name="info_profiling"
                          id="info_profiling"
                          value={info_profiling}
                          defaultChecked={info_profiling}
                          checked={info_profiling}
                          onChange={({ target: { checked } }) => {
                            SetInfoProfiling(!info_profiling);
                          }}
                      />
                      <label htmlFor="info_profiling" >Acconsento al trattamento per finalità di profilazione come previsto all’art. 2.4 dell’info_rmativa.</label>
                    </li>

                    <li><p className="note italic mb_0">Si ricorda all'interessato che potrà, in ogni tempo, sia opporsi a ciascuno dei trattamenti sopra elencati, sia modificare i consensi espressi in precedenza, anche potendo scegliere con quali mezzi essere contattato - es. email, sms, mms, fax, messaggi su social networks, telefono con operatore o posta cartacea.</p></li>
                  </ul>
                </fieldset>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              {(errors.phone === '1' || errors.email === '1') && <span className="alert-danger">Controlla i dati che hai inserito!</span>}
              <div className="button_container">
                <button
                  style={{
                    opacity: isReady ? 1 : 0.5,
                    transform: isReady ? "scale(1)" : "scale(.9)",
                    pointerEvents: isReady ? "all" : "none",
                  }}
                  className="cta green ml280"
                  onClick={goToNextStep}
                >
                  Prosegui
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
