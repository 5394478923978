import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { NavigateTo, CreateYearsArray } from "../helpers";
import { GetCities } from "../service";
import { GetItem, SetItem } from "../service/session";
import loaderSVG from "../assets/images/loader.svg";

export default function BirthPlaceDateScreen({ AgentAvatar }) {
  const H = useHistory();
  const D = new Date();
  const Y = D.getFullYear();
  const FR = useRef(null);
  const FirstInput = useRef(null);

  const [birthPlaceName, SetBirthPlaceName] = useState("");
  const [birthDay, SetBirthDay] = useState("");
  const [licenseYear, SetLicenseYear] = useState("");
  const [hasError] = useState("");
  const [isReady, SetIsReady] = useState(false);
  const [timoutForSearch, SetTimoutForSearch] = useState(null);
  const [cities, SetCities] = useState(null);
  const [birthPlace, SetBirthPlace] = useState(null);
  const [isForeign, SetIsForeign] = useState(null);
  const [showLoader, SetShowLoader] = useState(false);

  const goToNextStep = () => {
    SetItem("U_BP", birthPlace);
    SetItem("U_BD", birthDay);
    SetItem("U_FRG", isForeign);
    SetItem("U_LICENSE_YEAR", licenseYear);
    NavigateTo({
      path: "/informazioni",
      navigator: H,
    });
  };

  const SearchCities = () => {
    GetCities({ query: birthPlaceName }).then((res) => {
      if (res.statusCode === 200) {
        SetCities(res.data);
        SetShowLoader(false)
      } else {
        SetCities(null);
      }
    });
  };

  const SelectCity = (city) => {
    SetBirthPlaceName(city.comune);
    SetBirthPlace(city);
    SetIsForeign(null);
    FR.current.checked = false;
    SetCities(null);
  };

  useEffect(() => {
      const SetOldData = () => {
        let s_birthPlace = GetItem("U_BP");
        let s_birthDay = GetItem("U_BD");
        let s_isForeign = GetItem("U_FRG");
        let s_licenseYear = GetItem("U_LICENSE_YEAR");
        if (s_birthPlace.data !== null && s_birthPlace.data !== "null") {
          SelectCity(JSON.parse(s_birthPlace.data));
        }
        if (s_birthDay.data !== null) {
          SetBirthDay(s_birthDay.data);
        }
        if (s_isForeign.data !== null) {
          SetIsForeign(s_isForeign.data);
        }
        if (s_licenseYear.data !== null) {
          SetLicenseYear(s_licenseYear.data);
        }
      };
     SetOldData();
     FirstInput.current.focus();
   },[]);


  useEffect(() => {
    const CheckIfReady = () => {
      if (isForeign && birthDay !== "") {
        SetIsReady(true);
        return;
      }
      if (birthPlace && birthDay !== "") {
        SetIsReady(true);
        return;
      }
      SetIsReady(false);
      return;
    };
    CheckIfReady();
  }, [isForeign, birthPlace, birthDay]);

  const Years = CreateYearsArray(75);
  return (
    <div className="page">
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  <fieldset
                    className={cities && cities.length === 0 ? "wrong" : ""}
                  >
                    <label className="label">Comune di nascita</label>
                    <span className="input-with-loader">
                      <object className="loader birth-loader" style={{display: showLoader ? 'block' : 'none'}} title="Loader" type="image/svg+xml" data={loaderSVG} />
                    <input
                      ref={FirstInput}
                      type="text"
                      size="30"
                      name="comune_nascita"
                      id="comune_nascita"
                      placeholder="Comune di nascita"
                      autoComplete="off"
                      value={birthPlaceName}
                      onChange={({ target: { value } }) => {
                        if (timoutForSearch) {
                          clearTimeout(timoutForSearch);
                        }
                        SetBirthPlaceName(value);
                        SetTimoutForSearch(
                          setTimeout(() => {
                            if (value.length > 0) {
                              SearchCities(value);
                              SetShowLoader(true);
                            }
                          }, 1000)
                        );
                      }}
                    />
                    </span>
                    {cities && cities.length !== 0 && (
                      <div className="birth-places dropdown">
                        <ul>
                          {cities.map((city) => {
                            return (
                              <li
                                key={`key_${city.id}`}
                                onClick={() => SelectCity(city)}
                              >
                                {city.comune}
                                <span> ({city.provincia})</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                    <div className="col-full text_center">
                      <label className="label label_nr">
                        <input
                          ref={FR}
                          type="checkbox"
                          name="value"
                          id="value"
                          defaultChecked={isForeign}
                          onChange={({ target: { checked } }) => {
                            if (checked) {
                              SetBirthPlace(null);
                              SetBirthPlaceName("");
                            }
                            SetIsForeign(checked);
                          }}
                        />{" "}
                        nato all'estero
                      </label>
                    </div>
                  </fieldset>
                  <fieldset className={hasError ? "wrong" : ""}>
                    <label className="label">Data di nascita</label>
                    <input
                      type="date"
                      size="2"
                      id="nascita_data"
                      name="nascita_data"
                      im-insert="true"
                      min="1900-01-01"
                      max={`${Y - 10}-12-31`}
                      onChange={({ target: { value } }) => SetBirthDay(value)}
                      value={birthDay}
                    />
                    <span className="err wrong" style={{ opacity: 1 }}>
                      L'anno inserito non è corretto
                    </span>
                  </fieldset>
                  <fieldset className={hasError ? "wrong" : ""}>
                    <label className="label">Anno di conseguimento patente</label>
                    <select
                        className="first"
                        value={licenseYear}
                        onChange={({ target: { value } }) => {
                          SetLicenseYear(value)
                        }}
                    >
                      <option>- Anno -</option>
                      {Years.map((year) => {
                        return (
                            <option value={year} key={`key_year_${year}`}>
                              {year}
                            </option>
                        );
                      })}
                    </select>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="button_container">
                <button
                  style={{
                    opacity: isReady ? 1 : 0.5,
                    transform: isReady ? "scale(1)" : "scale(.9)",
                    pointerEvents: isReady ? "all" : "none",
                  }}
                  className="cta green ml280"
                  onClick={goToNextStep}
                >
                  Prosegui
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}