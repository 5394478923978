import { getData, postData } from "./config";
import configData from "../config.json";

export const GetProfessions = async () => getData("data-form/professions");
export const GetCivilStatuses = async () => getData("data-form/civil-status");
export const GetEducations = async () => getData("data-form/educations");
export const GetTheftProtectStatuses = async () => getData("data-form/theft-protections");
export const GetCarUses = async () => getData("data-form/car-uses");
export const GetCarParkings = async () => getData("data-form/car-parking");
export const GetPayments = async () => getData("data-form/payments");
export const GetCompanies = async () => getData("data-form/companies");
export const GetCompanyTypes = async () => getData("data-form/company-types");

//_PLATFORM API'S
//_FAST QUOTE
export const GetFastQuote = async ({ plate }) => getData(`fast-quote?palte=${plate}`);
export const GetFastQuoteInfo = async ({ requestId }) => getData(`get-fast-quote?requestId=${requestId}`);

//_INFO CAR
export const GetInfoCar = async ({ plate }) => getData(`infocar/get-info?plate=${plate}`);
export const GetFullInfoCar = async ({ plate }) => getData(`infocar/get-full-info?plate=${plate}`);
export const GetInfoCarBrands = async ({ month, year, product }) => getData(`infocar/brands?month=${month}&year=${year}&product=${product}`);
export const GetInfoCarModels = async ({ month, year, product, brand }) => getData(`infocar/models?month=${month}&year=${year}&product=${product}&brand=${brand}`);
export const GetInfoCarEquipments = async ({ month, year, product, model }) => getData(`infocar/equipments?month=${month}&year=${year}&product=${product}&model=${model}`);

//_LOCATION
export const GetCountries = async () => getData("location/countries");
export const GetDistricts = async () => getData("location/districts");
export const GetCities = async ({ query }) => getData(`location/cities?text=${query}`);
export const GetZipCodes = async ({ city }) => getData(`location/codes?city=${city}`);

//_REQUESTS
export const GetListOfQuotes = async ({ id, plate, token, customer, email, telephone}) => getData(`quotes?id=${id}&plate=${plate}&token=${token}&customer=${customer}&email=${email}&telephone=${telephone}`);

export const CreateQuote = async (largeData) =>postData("quotes", largeData );

export const GetInfoForRequest = async ({ requestId }) => getData(`quotes/${requestId}/details`);
export const SaveQuote = async ({requestId, product }) => postData(`quotes/${requestId}/save`, { product });
export const Requote = async ({requestId}) => postData("re-quote", requestId);
export const GetSavedQuoteInfo = async ({requestId, saveId }) => getData(`quotes/${requestId}/save/${saveId}`);

//_CMS
export const GetListPages = async () => getData("cms");
export const GetPageInfo = async ({slug}) => getData(`cms/${slug}`);
export const GetCoBrand = async ({hostname}) => postData(`cms/co-brand`, { hostname });

//_EXTERNAL API'S
//_GOOGLE
export const GetLoacationFromAdress = ({address}) => {
   return  fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${configData.GoogleMapsApiKey}`)
};
