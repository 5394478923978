// Auth key: 119c8c08e37ac203f652b210253619c7e501db02
// Marvel app: https://marvelapp.com/prototype/2620ahdg/screen/80466860
// Notes
// Fomato targa auto:  AA000AA, AA000000
// Formato targa motociclo; AA00000
// We will have buttons next/back
// &test for test

const BASE_URL = "https://stage-api-broker.quale.it/frontend/";

const makeHeaders = (token) => {
	return {
		Accept: "application/json",
		"Content-Type": "application/json",
		Auth: token,
	};
};

const makeBody = (data) => {
	return JSON.stringify(data);
};

export const getData = async (path) => {
	let URI = BASE_URL + path;
	let rData = {
		method: "GET",
		headers: makeHeaders("119c8c08e37ac203f652b210253619c7e501db02"),
	};

	return await fetch(URI, rData)
		.then((res) => {
			const statusCode = res.status;
			const data = res.json();
			return Promise.all([statusCode, data]);
		})
		.then(([statusCode, data]) => {
			return { data: data, statusCode: statusCode };
		})
		.catch((e) => console.log(e));
};

export const postData = async (path, data) => {
	let URI = BASE_URL + path;

	let rData = {
		method: "POST",
		headers: makeHeaders("119c8c08e37ac203f652b210253619c7e501db02"),
		body: makeBody(data),
	};

	return await fetch(URI, rData)
		.then((res) => {
			const statusCode = res.status;
			const data = res.json();
			return Promise.all([statusCode, data]);
		})
		.then(([statusCode, data]) => {
			return { data: data, statusCode: statusCode };
		})
		.catch((e) => console.log(e));
};
