export const SetItem = (key, value) => {
  let StringValue;
  if (typeof value === "number") {
    StringValue = value.toString();
  }
  if (typeof value === "string") {
    StringValue = value;
  }
  if (typeof value === "boolean") {
    StringValue = JSON.stringify(value);
  }

  if (typeof value === "undefined") {
    return {
      error: "Value is undefined",
    };
  }
  if (typeof value === "object") {
    StringValue = JSON.stringify(value);
  }
  try {
    sessionStorage.setItem(key, StringValue);
    return {
      error: false,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
};

export const GetItem = (key) => {
  try {
    let Item = sessionStorage.getItem(key);
    return {
      error: false,
      data: Item,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
};
export const RemoveItem = (key) => {
  try {
    sessionStorage.removeItem(key);
    return {
      error: false,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
};

export const ClearSession = () => {
  try {
    sessionStorage.clear();
    return {
      error: false,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
};
