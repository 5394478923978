import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FilterArrayByKeyValue, NavigateTo } from "../helpers";
import { GetItem, SetItem } from "../service/session";
import { Helmet } from "react-helmet";
import { GetCities } from "../service";

export default function RelativesCarDetailsScreen({ AgentAvatar, SeoContent }) {
  const [FirstName, SetFirstName] = useState("");
  const [LastName, SetLastName] = useState("");
  const [birthDay, SetBirthDay] = useState("");
  const D = new Date();
  const Y = D.getFullYear();
  const [hasError] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [birthPlaceName, SetBirthPlaceName] = useState("");
  const [timoutForSearch, SetTimoutForSearch] = useState(null);
  const [cities, SetCities] = useState(null);
  const [birthPlace, SetBirthPlace] = useState(null);
  const [meritClass, SetMeritClass] = useState("");
  const [targa, SetTarga] = useState("");
  const [berVeicolo, SetBerVeicolo] = useState("auto");
  const [berFamTipo, SetBerFamTipo] = useState("M");

  const H = useHistory();
  const { data } = GetItem("INS_TYPE");
  const InsuranceType = data;

  let SEO = null;

  if (InsuranceType === "A") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }

  const SearchCities = () => {
    GetCities({ query: birthPlaceName }).then((res) => {
      if (res.statusCode === 200) {
        SetCities(res.data);
      } else {
        SetCities(null);
      }
    });
  };

  const SelectCity = (city) => {
    SetBirthPlaceName(city.comune);
    SetBirthPlace(city);
    SetCities(null);
  };

  useEffect(() => {
    const SetOldData = () => {
      let s_FirstName = GetItem("F_U_NAME");
      let s_LastName = GetItem("F_U_LSNAME");
      let s_birthPlace = GetItem("F_U_BP");
      let s_birthDay = GetItem("F_U_BD");
      let s_targa = GetItem("F_CAR_T");
      let s_meritClass = GetItem("F_CAR_MERIT");

      let s_berFamTipo = GetItem("F_U_BER_TIPO");
      let s_berVeicolo = GetItem("F_CAR_BER_VEICOLO");

      if (s_FirstName.data !== null) {
        SetFirstName(s_FirstName.data);
      }

      if (s_LastName.data !== null) {
        SetLastName(s_LastName.data);
      }

      if (s_birthPlace.data !== null && s_birthPlace.data !== "null") {
        SelectCity(JSON.parse(s_birthPlace.data));
      }

      if (s_birthDay.data !== null) {
        SetBirthDay(s_birthDay.data);
      }

      if (s_targa.data !== null) {
        SetTarga(s_targa.data);
      }

      if (s_meritClass.data !== null) {
        SetMeritClass(s_meritClass.data);
      }
      if(s_berFamTipo.data !== null){
        SetBerFamTipo(s_berFamTipo.data);
      }
      if(s_berVeicolo.data !== null){
        SetBerFamTipo(s_berVeicolo.data);
      }
    };
    SetOldData();
  }, []);

  useEffect(() => {
    const CheckIfReady = () => {
      if (
        FirstName !== "" &&
        LastName !== "" &&
        birthPlace &&
        birthDay !== "" &&
        targa !== "" &&
        meritClass !== ""
      ) {
        setIsReady(true);
        return;
      }
      setIsReady(false);
      return;
    };
    CheckIfReady();
  }, [FirstName, LastName, birthPlace, birthDay, targa, meritClass]);

  const goToNextStep = () => {
    SetItem("F_U_NAME", FirstName);
    SetItem("F_U_LSNAME", LastName);
    SetItem("F_U_BP", birthPlace);
    SetItem("F_U_BD", birthDay);
    SetItem("F_U_BER_TIPO", berFamTipo);
    SetItem("F_CAR_T", targa);
    SetItem("F_CAR_MERIT", meritClass);
    SetItem("F_CAR_BER_VEICOLO", berVeicolo);
    
    return NavigateTo({
      path: "/seleziona-guida",
      navigator: H,
    });
  };
  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO && SEO.result.title}</title>
          <meta name="title" content={SEO && SEO.result.meta_title} />
          <meta
            name="description"
            content={SEO && SEO.result.meta_description}
          />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
                <h2 className="text_black">
                  Dati del veicolo del familiare di cui utilizzi la classe di
                  merito.
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  <fieldset>
                    <label className="label">Nome</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Nome"
                      im-insert="true"
                      value={FirstName}
                      onChange={({ target: { value } }) => SetFirstName(value)}
                    />
                  </fieldset>
                  <fieldset>
                    <label className="label">Cognome</label>
                    <input
                      type="text"
                      id="surname"
                      name="surname"
                      placeholder="Cognome"
                      im-insert="true"
                      value={LastName}
                      onChange={({ target: { value } }) => SetLastName(value)}
                    />
                  </fieldset>

                  <fieldset className={hasError ? "wrong" : ""}>
                    <label className="label">Data di nascita</label>
                    <input
                      type="date"
                      size="2"
                      id="nascita_data"
                      name="nascita_data"
                      im-insert="true"
                      min="1900-01-01"
                      max={`${Y - 10}-12-31`}
                      onChange={({ target: { value } }) => SetBirthDay(value)}
                      value={birthDay}
                    />
                    <span className="err wrong" style={{ opacity: 1 }}>
                      L'anno inserito non è corretto
                    </span>
                  </fieldset>

                  <fieldset
                    className={cities && cities.length === 0 ? "wrong" : ""}
                  >
                    <label className="label">Comune di nascita</label>
                    <input
                      type="text"
                      size="30"
                      name="comune_nascita"
                      id="comune_nascita"
                      placeholder="Comune di nascita"
                      autoComplete="off"
                      value={birthPlaceName}
                      onChange={({ target: { value } }) => {
                        if (timoutForSearch) {
                          clearTimeout(timoutForSearch);
                        }
                        SetBirthPlaceName(value);
                        SetTimoutForSearch(
                          setTimeout(() => SearchCities(value), 1000)
                        );
                      }}
                    />
                    {cities && cities.length !== 0 && (
                      <div className="birth-places dropdown">
                        <ul>
                          {cities.map((city) => {
                            return (
                              <li
                                key={`key_${city.id}`}
                                onClick={() => SelectCity(city)}
                              >
                                {city.comune}
                                <span> ({city.provincia})</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </fieldset>

                  <fieldset>
                    <label className="label">Sesso</label>
                    <select
                        value={berFamTipo}
                        onChange={({ target: { value } }) => {
                          SetBerFamTipo(value);
                        }}
                    >
                      <option value="M">Uomo</option>
                      <option value="F">Donna</option>
                    </select>
                  </fieldset>

                  <fieldset>
                    <label className="label">
                      Targa del veicolo di cui utilizzi la classe di merito
                    </label>
                    <input
                      type="text"
                      placeholder="Scrivi targa veicolo"
                      autoComplete="off"
                      value={targa}
                      onChange={({ target: { value } }) => {
                        SetTarga(value);
                      }}
                    />
                  </fieldset>

                  <fieldset>
                    <label className="label">
                      Classe di merito CU del veicolo già assicurato
                    </label>
                    <input
                      type="number"
                      min="1"
                      max="14"
                      step="1"
                      placeholder="Valore numero 1-14"
                      autoComplete="off"
                      value={meritClass}
                      onChange={({ target: { value } }) => {
                        SetMeritClass(value);
                      }}
                    />
                  </fieldset>

                  <fieldset>
                    {/* <label className="label">TYPE</label> */}
                    <select
                        value={berVeicolo}
                        onChange={({ target: { value } }) => {
                          SetBerVeicolo(value);
                        }}
                    >
                      <option value="auto">Auto</option>
                      <option value="moto">Moto</option>
                      <option value="furgone">Furgone</option>
                    </select>
                  </fieldset>

                  <div className="button_container">
                    <button
                      style={{
                        opacity: isReady ? 1 : 0.5,
                        transform: isReady ? "scale(1)" : "scale(.9)",
                        pointerEvents: isReady ? "all" : "none",
                      }}
                      className="cta green ml280"
                      onClick={goToNextStep}
                    >
                      Prosegui
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
