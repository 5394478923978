import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { FilterArrayByKeyValue, NavigateTo } from "../helpers";
import { GetItem, SetItem } from "../service/session";
import { CreateQuote } from "../service";
export default function DirectCompensationScreen({ AgentAvatar, SeoContent }) {
  const H = useHistory();
  const { data } = GetItem("INS_TYPE");
  const InsuranceType = data;
  let SEO = null;
  if (InsuranceType === "A") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }
  const [isReady, SetIsReady] = useState(false);
  const [DirectCompensation, SetDirectCompensation] = useState(false);


  const SelectAnswer = ({ target: { value } }) => {
    SetDirectCompensation(value);
    SetIsReady(true);
  };

  const getAssType = (type) => {
    let CurrentY = new Date().getFullYear();
    let CarPurchaseYer = GetItem("CAR_P_Y").data;
    if(type === "R") {
      return "bm"
    }
    if( CarPurchaseYer !==  CurrentY){
      return "usata";
    }
    return "nuova"
  }

  const goToNextStep = () => {
    SetItem("D_COMPENSATION", DirectCompensation);
    let s_product = GetItem("INS_TYPE").data;
    //s_ -> session
    if(s_product === "A"){
      s_product = "auto"
    }
    if(s_product === "M") {
      s_product = "moto"
    }
    if(s_product === "F") {
      s_product = "autocarro"
    }
    let s_birthDay = GetItem("U_BD").data;
    s_birthDay = s_birthDay.split("-");

    let s_carInfo = JSON.parse(GetItem("CAR_INFO").data);
    console.log(s_carInfo);
    let s_carInfo_DtMt = s_carInfo.dataimmatricolazione.split("-");

    let RquestData = {
      product: s_product,
      cont_prop: 1,
      cont_cond: 1,
      prop_cond: 1,
      cont_tipo: GetItem("U_CONT_TIPO").data,
      cont_nome: GetItem("U_NAME").data,
      cont_residenza_provincia: GetItem("U_PROVINCE").data,
      cont_residenza_comune: GetItem("U_CITY").data,
      cont_residenza_cap:  GetItem("U_POSTAL_CODE").data,
      cont_residenza_indirizzo: GetItem("U_ADR").data,
      cont_residenza_civico: GetItem("U_HNR").data,
      cont_residenza_domicilio: 1,
      cont_email: GetItem("U_EMAIL").data,
      cont_telefono: GetItem("U_PHONE").data,
      cont_figli_17: 0,
      cont_patente_anno: GetItem("U_LICENSE_YEAR").data,
      cont_nascita_data_year: s_birthDay[0],
      cont_nascita_data_month: s_birthDay[1],
      cont_nascita_data_day: s_birthDay[2],
      cont_nascita_comune: JSON.parse(GetItem("U_BP").data).id,
      cont_cognome: GetItem("U_LSNAME").data,
      cont_eta_familiare_patente: 26,
      cont_stato: GetItem("U_CST").data,
      cont_studio: GetItem("U_EDU").data,
      cont_professione: GetItem("U_PRO").data,
      // ass_data_decorrenza_year: "CAR_INS_START_YEAR ?", // last field  in DetailsCompletedScreen
      // ass_data_decorrenza_month: "CAR_INS_START_MONTH ?", // last field  in DetailsCompletedScreen
      // ass_data_decorrenza_day: "CAR_INS_START_DAY ?", // last field  in DetailsCompletedScreen
      ass_guida_esperta: GetItem("GUIDE").data === "esperta",
      ass_guida_esclusiva: GetItem("GUIDE").data === "escluziva",
      ass_cond_piu_giovane: GetItem("GUIDE").data === "libera",
      ass_tipo: getAssType(GetItem("INS_PZ_TYPE").data),
      ass_companies: JSON.parse(GetItem("CAR_INFO").data).brandId,
      ass_classe_bm: GetItem("MERIT_CLASS").data,
      ass_anni_assicurato: getAssType(GetItem("INS_PZ_TYPE").data) === "nuova" ? 0 : new Date().getFullYear() - JSON.parse(GetItem("CAR_P_Y").data),
      ass_usato_bersani: GetItem("REUSE_MERIT").data ? GetItem("REUSE_MERIT").data : false,
      ass_domandone: 0,
      ass_modalita_pagamento: 1,
      sin_5_anni: GetItem("VIOLATIONS").data === 'yes'? GetItem("VIOLATIONS_NR").data : 0,
      ber_tipo:  GetItem("REUSE_MERIT").data? GetItem("REUSE_MERIT").data === "yes_my_car" ? "mio" : "fam" : "no",
      ber_targa: GetItem("REUSE_MERIT_TARGA").data? GetItem("REUSE_MERIT_TARGA").data : "",
      ber_veicolo: GetItem("F_CAR_BER_VEICOLO").data? GetItem("F_CAR_BER_VEICOLO").data :"",
      ber_fam_tipo: GetItem("F_U_BER_TIPO").data? GetItem("F_U_BER_TIPO").data:"",

      info_1: JSON.parse(GetItem("P_INFO1").data),
      info_2: JSON.parse(GetItem("P_INFO2").data),
      info_3: JSON.parse(GetItem("P_INFO3").data),
      info_4: JSON.parse(GetItem("P_INFO4").data),
      info_5: JSON.parse(GetItem("P_INFO5").data),
      info_10: JSON.parse(GetItem("P_INFO10").data),

      auto_targa: GetItem("CAR_P").data,
      auto_marca: s_carInfo.marca,
      auto_modello:s_carInfo.modello,
      auto_versione:s_carInfo.allestimenti[0].allestimento,
      auto_annoxx: s_carInfo_DtMt[0],
      auto_mesexx: s_carInfo_DtMt[1],
      auto_data_imm_year: s_carInfo_DtMt[0],
      auto_antifurto: GetItem("CAR_ANTITHEFT").data,
      auto_gancio_traino: GetItem("CAR_ANTITHEFT").data === 'yes',
      auto_utilizzo: GetItem("CAR_USAGE").data,
      auto_ricovero: GetItem("CAR_PARKING").data,
      auto_km_anno: GetItem("CAR_USAGE_KILOMETERS").data,
      auto_number: GetItem("CAR_FAMILY_MEMBERS").data,
      auto_anno_acquisto: getAssType(GetItem("INS_PZ_TYPE").data) === "nuova" ?  new Date().getFullYear() : GetItem("CAR_P_Y").data,
      auto_altra_alimentazione: GetItem("CAR_OTHER_ALIM").data? GetItem("CAR_OTHER_ALIM").data === "GPL"? "G" : "M" : null
    };

    if(RquestData.sin_5_anni){
      let Violations = JSON.parse(GetItem("VIOLATIONS_DATA").data);
      Violations.forEach(( violation, index ) => {
        RquestData[`sin_anno_${index+1}`] = violation.year;
        RquestData[`sin_tipo_${index+1}`] = violation.type;
      });
    }

    console.log(RquestData);
    CreateQuote(RquestData).then((res)=>{
      console.log(res);
    })
    
    NavigateTo({
      path: "/preventivo",
      navigator: H,
    });
  };

  const SetOldData = () => {
    let s_DirectCompensation = GetItem("D_COMPENSATION");
    if (s_DirectCompensation.data) {
      SetDirectCompensation(s_DirectCompensation.data);
      SetIsReady(true);
    }
  };

  useEffect(() => {
    SetOldData();
  }, []);

  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO && SEO.result.title}</title>
          <meta name="title" content={SEO && SEO.result.meta_title} />
          <meta
            name="description"
            content={SEO && SEO.result.meta_description}
          />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  <fieldset>
                    <label className="label">
                      Desideri ottenere preventivi senza risarcimento diretto?
                    </label>

                    <ul className="standard_radio inline">
                     
                      <li>
                        <input
                          type="radio"
                          id="yes_compensation"
                          name="compensation"
                          value="yes"
                          checked={DirectCompensation === "yes"}
                          onChange={SelectAnswer}
                        />
                        <label htmlFor="yes_compensation">Si</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="no_compensation"
                          name="compensation"
                          value="no"
                          checked={DirectCompensation === "no"}
                          onChange={SelectAnswer}
                        />
                        <label htmlFor="no_compensation">No</label>
                      </li>
                    </ul>
                  </fieldset>

                  <a className="" href="#" target="_blank">
                    <fieldset>
                      <span style={{ color: "red" }}>
                        Vuoi approfondire meglio?
                      </span>
                    </fieldset>
                  </a>

                  <div className="button_container">
                    <button
                      style={{
                        opacity: isReady ? 1 : 0.5,
                        transform: isReady ? "scale(1)" : "scale(.9)",
                        pointerEvents: isReady ? "all" : "none",
                      }}
                      className="cta green ml280"
                      onClick={goToNextStep}
                    >
                      Prosegui
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
