export function CheckIfStringExists(_Item, _ArrayWithTexts) {
  return _ArrayWithTexts.includes(_Item);
}

export function NavigateTo({ path, params, navigator }) {
  if (params && typeof params === "object") {
    return navigator.push(path, params);
  }
  return navigator.push(path);
}

export function FilterArrayByKeyValue(arr, key, value) {
  let Result = arr.filter((item) => item[key] === value);
  if (Result.length !== 0) {
    return {
      result: Result[0],
    };
  }
  return {
    result: null,
  };
}

export function CreateYearsArray(limit){
  var year = (new Date()).getFullYear();
  let Years = [];
  let Limit = limit || 60;
  for(let i = 0; i < Limit; i++ ){
    Years.push(year-i);
  }
  return Years;
}

export function  MakeKilometersArray({min, max, step})
{
  let Kilometers = [];
  if(min !== step){
    Kilometers.push(min);
  }
  for(let i=1; (i*step) <= max; i++ ){
    Kilometers.push(i*step);
  }

  return Kilometers;

}
