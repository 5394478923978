import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GetItem, SetItem } from "../service/session";
import AutoSvg from "../assets/images/rca.svg";
import AutoCarroSvg from "../assets/images/rcautocarro.svg";
import MotoSvg from "../assets/images/rcmoto.svg";
import { NavigateTo } from "../helpers";

export default function SelectInsuranceScreen({ AgentAvatar }) {
  const H = useHistory();
  const Location = useLocation();
  const [message, SetMessage] = useState(null);
  const [isReady, SetIsReady] = useState(false);
  const [insuranceType, SetInsuranceType] = useState(false);

  const goToNextStep = () => {
    SetItem("INS_TYPE", insuranceType);
    if (insuranceType === "A") {
      NavigateTo({
        path: "/polizza/auto",
        navigator: H,
      });
    }

    if (insuranceType === "F") {
      NavigateTo({
        path: "/polizza/autocarro",
        navigator: H,
      });
    }

    if (insuranceType === "M") {
      NavigateTo({
        path: "/polizza/moto",
        navigator: H,
      });
    }
  };

  const SelectTypeAndActivateNext = ({ target: { value } }) => {
    SetInsuranceType(value);
    SetIsReady(true);
  };
  const SetOldData = () => {
    let s_insuranceType = GetItem("INS_TYPE");
    if (s_insuranceType.data) {
      SetInsuranceType(s_insuranceType.data);
      SetIsReady(true);
    }
  };
  useEffect(() => {
    SetOldData();
    if (Location && Location.state && Location.state.message) {
      SetMessage(Location.state.message);
    }
  }, [Location]);

  return (
    <div className="page">
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  <fieldset>
                    {message && (
                      <p
                        className="as-message"
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}

                    <label className="label">Tipologia di assicurazione</label>

                    <ul className="standard_radio inline triple_field">
                      <li>
                        <input
                          type="radio"
                          size="14"
                          id="rca"
                          name="tipologia"
                          value="A"
                          checked={insuranceType === "A"}
                          onChange={SelectTypeAndActivateNext}
                        />
                        <label htmlFor="rca" className="zindex1">
                          <span className="display_ib">
                            <object
                              type="image/svg+xml"
                              data={AutoSvg}
                              className="icon icon_w50 icon_add"
                              aria-label="Auto"
                            ></object>
                          </span>
                          <br />
                          Auto
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          size="14"
                          id="autocarro"
                          name="tipologia"
                          value="F"
                          checked={insuranceType === "F"}
                          onChange={SelectTypeAndActivateNext}
                        />{" "}
                        <label htmlFor="autocarro" className="zindex1">
                          <span className="display_ib">
                            <object
                              type="image/svg+xml"
                              data={AutoCarroSvg}
                              className="icon icon_w50 icon_add"
                              aria-label="AutoCarro"
                            ></object>
                          </span>
                          <br />
                          Autocarro
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          size="14"
                          id="moto"
                          name="tipologia"
                          value="M"
                          checked={insuranceType === "M"}
                          onChange={SelectTypeAndActivateNext}
                        />{" "}
                        <label htmlFor="moto" className="zindex1">
                          <span className="display_ib">
                            <object
                              type="image/svg+xml"
                              data={MotoSvg}
                              className="icon icon_w50 icon_add"
                              aria-label="Moto"
                            ></object>
                          </span>
                          <br />
                          Moto
                        </label>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="button_container">
                <button
                  style={{
                    opacity: isReady ? 1 : 0.5,
                    transform: isReady ? "scale(1)" : "scale(.9)",
                    pointerEvents: isReady ? "all" : "none",
                  }}
                  className="cta green ml280"
                  onClick={goToNextStep}
                >
                  Prosegui
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
