import React, { useState, useEffect, useRef } from "react";
import { FilterArrayByKeyValue, NavigateTo } from "../helpers";
import { GetItem, SetItem } from "../service/session";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

export default function MeritClassScreen({ SeoContent, AgentAvatar }) {
  const H = useHistory();
  const { data } = GetItem("INS_TYPE");
  const InsuranceType = data;
  let SEO = null;
  if (InsuranceType === "A") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }
	const [isReady, SetIsReady] = useState(false);
  const [meritClass, SetMeritClass] = useState("");
  const FirstInput = useRef(null);

	const goToNextStep = () => {
		if( meritClass === "" || meritClass === 0 || meritClass > 15 ){
			 SetIsReady(false);
       return;
		}
    SetItem("MERIT_CLASS", meritClass);
    NavigateTo({
      path: "/incidenti",
      navigator: H,
    });
  };

  useEffect(() => {
    const SetOldData = () => {
      let s_meritClass = GetItem("MERIT_CLASS");
      if (s_meritClass.data) {
        SetMeritClass(s_meritClass.data);
        SetIsReady(true);
      }
    };
    SetOldData();
    FirstInput.current.focus();
  }, []);

  useEffect(() => {
		const CheckIfReady = () => {
      if ( meritClass !== "" && meritClass < 15 && meritClass !== 0 ) {
        SetIsReady(true);
        return;
      }
      return;
    };
    CheckIfReady();
  }, [meritClass]);

  return (
    <div className="page">
      {SEO && (
        <Helmet>
          <title>{SEO && SEO.result.title}</title>
          <meta name="title" content={SEO && SEO.result.meta_title} />
          <meta
            name="description"
            content={SEO && SEO.result.meta_description}
          />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="rform">
                  <fieldset>
										<label className="label">Il quale classe di merito sei?</label>
										<input
                      ref={FirstInput}
                      type="number"
                      min="1"
											max="14"
											step="1"
                      name="comune_nascita"
                      id="comune_nascita"
                      placeholder="Valore numero 1-14"
                      autoComplete="off"
                      value={meritClass}
                      onChange={({ target: { value } }) => {
                        SetMeritClass(value);
                      }}
                    />
									</fieldset>

                  <a className="" href="#" target="_blank">
                    <fieldset>
                      <span style={{ color: "red" }}>
                        Vuoi approfondire meglio?
                      </span>
                    </fieldset>
                  </a>

                  <div className="button_container">
                    <button
                      style={{
                        opacity: isReady ? 1 : 0.5,
                        transform: isReady ? "scale(1)" : "scale(.9)",
                        pointerEvents: isReady ? "all" : "none",
                      }}
                      className="cta green ml280"
                      onClick={goToNextStep}
                    >
                      Prosegui
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
