import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {useHistory} from "react-router-dom";
import {FilterArrayByKeyValue, NavigateTo} from "../helpers";
import {
  GetCarParkings,
  GetCarUses,
  GetInfoCarBrands,
  GetInfoCarEquipments,
  GetInfoCarModels, 
  GetTheftProtectStatuses
} from "../service";
import loaderSVG from "../assets/images/loader-white.svg";

import {GetItem, SetItem} from "../service/session";
import moment from "moment";
import DynamicSelect from "./components/DynamicSelect";

export default function CarDetailsScreen({SeoContent, AgentAvatar}) {
  const H = useHistory();
  const {data} = GetItem("INS_TYPE");
  const InsuranceType = data;
  const D = new Date();
  const Y = D.getFullYear();
  const FuelTypes = {
    "B": "Benzina (gasoline)",
    "D": "Diesel (diesel)",
    "G": "Gpl (liquefied petroleum gas”)",
    "M": "Metano (methane)",
    "E": "Elettrica (electric)"
  }
  let SEO = null;
  let INSTYPE = null;
  if (InsuranceType === "A") {
    INSTYPE = "auto";
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    INSTYPE = "moto";
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    INSTYPE = "autocarro";
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }

  const [carDetails, SetCarDetails] = useState(false);
  const [IsLoading, SetIsLoading] = useState(false);
  const [matriculationDay, SetMatriculationDay] = useState("");
  const [brands, SetBrands] = useState(null);
  const [brand, SetBrand] = useState(null);
  const [models, SetModels] = useState(null);
  const [model, SetModel] = useState(null);
  const [equipments, SetEquipments] = useState(null);
  const [equipment, SetEquipment] = useState(null);
  const [fuelType, SetFuelType] = useState(null);
  const [detailsEquipment, SetDetailsEquipment] = useState(null);
  const [isReady, SetIsReady] = useState(false);
  const [brandId, SetBrandId] = useState('');

  const RenderFuelType = (fuelCodes) => {
    let fuelType = "";
    fuelCodes.forEach(code => {
      fuelType += FuelTypes[code] + " / ";
    });
    return fuelType.replace(/ \/ $/, '');
  }

  const goToNextStep = () => {
    NavigateTo({
      path: "/confermare-dettagli",
      navigator: H,
    });
  };

  const LoadOtherDetails = async () => {
    SetIsLoading(true);
    let TheftProtections = await GetTheftProtectStatuses();
    let CarUses = await GetCarUses();
    let CarParkings = await GetCarParkings();
    SetItem("THEFT_PROTECTIONS", TheftProtections.data);
    SetItem("CAR_USES", CarUses.data);
    SetItem("CAR_PARKINGS", CarParkings.data);
    SetItem("CAR_DETAILS", carDetails);
    SetIsLoading(false);
    goToNextStep()
  }

  useEffect(() => {
    const GetCarDetails = async () => {
      let CarInfo = JSON.parse(GetItem("CAR_INFO").data);
      if (CarInfo) {
        SetIsReady(true);
        let InfoDate = moment(CarInfo.dataimmatricolazione);
        let InfoMonth = InfoDate.format('M');
        let InfoYear = InfoDate.format('Y');
        await GetInfoCarBrands({
          month: InfoMonth,
          year: InfoYear,
          product: INSTYPE
        }).then((res) => {
          setBrandId(res.data.data, CarInfo, true);
        });
        SetCarDetails(CarInfo);
      }
    };
    GetCarDetails();
  }, []);

  useEffect(() => {
    const LoadFormData = async () => {
      if (!brands) {
        const matriculation = getMatriculation();
        if (matriculation) {
          await GetInfoCarBrands({
            month: matriculation.month,
            year: matriculation.year,
            product: INSTYPE
          }).then((res) => {
            SetBrands(res.data.data);
          });
        }
      }
    };
    LoadFormData();
  }, [matriculationDay])

  useEffect(() => {
    setBrandId(brands, {}, false);
    const LoadFormData = async () => {
      const matriculation = getMatriculation();
      if (matriculation) {
        await GetInfoCarModels({
          month: matriculation.month,
          year: matriculation.year,
          product: INSTYPE,
          brand
        }).then((res) => {
          SetModels(res.data.data);
        });
      }
    };
    LoadFormData();
  }, [brand])

  useEffect(() => {
    const LoadFormData = async () => {
      const matriculation = getMatriculation();
      if (matriculation) {
        await GetInfoCarEquipments({
          month: matriculation.month,
          year: matriculation.year,
          product: INSTYPE,
          model
        }).then((res) => {
          const _equipments = res.data.data.map(item => {
            return {
              id: INSTYPE === 'moto' ? item.CODALL + item.PRGALL : item.CODALL,
              value: item.DESALL || 'Base',
              engine: parseInt(item.CILIND),
              month: item.MESEXX,
              year: item.ANNOXX,
              doors: parseInt(item.NUMPOR),
              powerKW: parseInt(item.POTKWX),
              powerCV: parseInt(item.POTCVX),
            }
          });
          SetEquipments(_equipments);
        });
      }
    };
    LoadFormData();
  }, [model])

  useEffect(() => {
    const LoadFormData = async () => {
      if (equipment) {
        const selected = equipments.filter(item => {
          return equipment === item.id;
        });
        SetDetailsEquipment(selected[0]);
      }
    };
    LoadFormData();
  }, [equipment])

  useEffect(() => {
    const setCarInfo = () => {
      if (equipment && detailsEquipment) {
        console.log(brandId);
        const carInfo = {
          brandId: brandId,
          allestimenti: [
            {
              alimentazione: [
                fuelType
              ],
              allestimento: getLabel(equipments, equipment),
              cilindrata: String(detailsEquipment.engine),
              cv: detailsEquipment.powerCV,
              kw: detailsEquipment.powerKW,
              porte: String(detailsEquipment.doors),
            }
          ],
          dataimmatricolazione: matriculationDay,
          marca: getLabel(brands, brand),
          modello: getLabel(models, model),
          primaimmatricolazione: false,
          targa: GetItem('CAR_P').data
        };
        SetItem('CAR_INFO', carInfo);
        SetIsReady(true);
      }
    }
    setCarInfo();
  }, [detailsEquipment, fuelType])

  const getFuelTypes = () => {
    let optionsFuel = [];
    for (const property in FuelTypes) {
      optionsFuel.push(<option
          key={`key_s_${property}`}
          value={property}
      >
        {FuelTypes[property]}
      </option>)
    }
    return optionsFuel;
  }

  const getMatriculation = () => {
    if (matriculationDay && matriculationDay.length > 0) {
      let matricualtion = matriculationDay.split('-');
      if (matricualtion && matricualtion.length > 0) {
        return {month: Number(matricualtion[1]), year: Number(matricualtion[0])}
      }
    }
    return false;
  }

  const getLabel = (elements, element) => {
    return elements.filter(item => {
      return item.id === element
    })[0].value
  }

  const setBrandId = (response, CarInfo, updateCarInfo) => {
    if (response) {
      const itemBrand = response.filter(item => {
        return item.value === updateCarInfo ? CarInfo.marca : brand;
      });
      if (itemBrand[0] && itemBrand[0].id.length > 0) {
        if (updateCarInfo) {
          CarInfo.brandId = itemBrand[0].id;
          SetItem('CAR_INFO', CarInfo)
        } else {
          SetBrandId(itemBrand[0].id);
        }
      }
    }
  }

  return (
      <div className="page">
        {SEO && (
            <Helmet>
              <title>{SEO.result.title}</title>
              <meta name="title" content={SEO.result.meta_title}/>
              <meta name="description" content={SEO.result.meta_description}/>
            </Helmet>
        )}
        <div className="fade-cover"/>
        <main className="col-full">
          <section className="col-full title_section text_center">
            <div className="center-block">
              <div className="col-group">
                <div className="col-full">
                  <div className="avatar">
                    <img src={AgentAvatar} alt="Agent Avatar"/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="col-full text_center form_section">
            <div className="center-block">
              <div className="col-group">
                <div className="col-full">
                  {!carDetails && (
                      <div className="rform">
                        <fieldset className="no-hover">
                          <label className="label">
                            Aiutami a trovare la tua auto
                            <br/>
                            Compila i dati
                          </label>
                        </fieldset>
                        <ul className="data_summary">
                          <li>
                            <span>Data di immatricolazione:</span>
                            <input
                                type="date"
                                size="2"
                                id="nascita_data"
                                name="nascita_data"
                                im-insert="true"
                                min="1900-01-01"
                                max={`${Y - 10}-12-31`}
                                onChange={({target: {value}}) => SetMatriculationDay(value)}
                                value={matriculationDay}
                            />
                          </li>
                          <li>
                            <span>Marca:</span>
                            <DynamicSelect name={"brand"} value={brand} label={"Marca"} elements={brands}
                                           SetValue={SetBrand}/>
                          </li>
                          <li>
                            <span>Modello:</span>
                            <DynamicSelect name={"model"} value={model} label={"Modello"} elements={models}
                                           SetValue={SetModel}/>
                          </li>
                          <li>
                            <span>Allestimenti/versione:</span>
                            <DynamicSelect name={"equipment"} value={equipment} label={"Allestimento"}
                                           elements={equipments} SetValue={SetEquipment}/>
                          </li>
                          <li>
                            <span>Alimentazione:</span>
                            <select
                                id="fuelType"
                                name="fuelType"
                                value={fuelType}
                                onChange={({ target: { value } }) => {
                                  SetFuelType(value);
                                }}
                            >
                              <option value="">Scegli Alimentazione</option>
                              {getFuelTypes()}
                            </select>
                          </li>
                          {detailsEquipment && (
                              <>
                                <li>
                                  <span>Cilindrata:</span>
                                  <select id="" name="">
                                    <option value={detailsEquipment.engine}>{detailsEquipment.engine}</option>
                                  </select>
                                </li>
                                <li>
                                  <span>KW:</span>
                                  <select id="" name="">
                                    <option value={detailsEquipment.powerKW}>{detailsEquipment.powerKW}</option>
                                  </select>
                                </li>
                                <li>
                                  <span>Cavalli:</span>
                                  <select id="" name="">
                                    <option value={detailsEquipment.powerCV}>{detailsEquipment.powerCV}</option>
                                  </select>
                                </li>
                                <li>
                                  <span>Porte:</span>
                                  <select id="" name="">
                                    <option value={detailsEquipment.doors}>{detailsEquipment.doors}</option>
                                  </select>
                                </li>
                              </>
                          )}
                        </ul>
                      </div>
                  )}
                  {carDetails && (
                      <div className="rform">
                        <fieldset className="no-hover">
                          <label className="label">
                            Ottimo!
                            <br/>
                            Ora ho tutti i dati che mi servono
                          </label>
                          <ul className="data_summary">
                            <li>
                              <span>Data di immatricolazione:</span>
                              <select id="" name="" disabled>
                                <option value={carDetails.dataimmatricolazione}
                                        selected>{moment(carDetails.dataimmatricolazione).format('DD/MM/YYYY')}</option>

                              </select>
                            </li>
                            <li>
                              <span>Marca:</span>
                              <select id="" name="" disabled>
                                <option value={carDetails.marca}>{carDetails.marca}</option>
                              </select>
                            </li>
                            <li>
                              <span>Modello:</span>
                              <select id="" name="" disabled>
                                <option value={carDetails.modello}>{carDetails.modello}</option>
                              </select>
                            </li>
                            <li>
                              <span>Allestimenti/versione:</span>
                              <select id="" name="">
                                <option
                                    value={carDetails.allestimenti[0].allestimento}>{carDetails.allestimenti[0].allestimento}</option>
                              </select>
                            </li>
                            <li>
                              <span>Alimentazione:</span>
                              <select id="" name="">
                                <option
                                    value={carDetails.allestimenti[0].alimentazione}>{RenderFuelType(carDetails.allestimenti[0].alimentazione)}</option>
                              </select>
                            </li>
                            <li>
                              <span>Cilindrata:</span>
                              <select id="" name="">
                                <option
                                    value={carDetails.allestimenti[0].cilindrata}>{carDetails.allestimenti[0].cilindrata}</option>
                              </select>
                            </li>
                            <li>
                              <span>KW:</span>
                              <select id="" name="">
                                <option value={carDetails.allestimenti[0].kw}>{carDetails.allestimenti[0].kw}</option>
                              </select>
                            </li>
                            <li>
                              <span>Cavalli:</span>
                              <select id="" name="">
                                <option value={carDetails.allestimenti[0].cv}>{carDetails.allestimenti[0].cv}</option>
                              </select>
                            </li>
                            <li>
                              <span>Porte:</span>
                              <select id="" name="">
                                <option
                                    value={carDetails.allestimenti[0].porte}>{carDetails.allestimenti[0].porte}</option>
                              </select>
                            </li>
                          </ul>
                        </fieldset>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="col-full text_center form_section">
            <div className="center-block">
              <div className="col-group">
                <div className="button_container">
                  {!IsLoading ? (
                          <button
                              style={{
                                opacity: isReady ? 1 : 0.5,
                                transform: isReady ? "scale(1)" : "scale(.9)",
                                pointerEvents: isReady ? "all" : "none",
                              }}
                              className="cta green ml280"
                              onClick={LoadOtherDetails}
                          >
                            Prosegui
                          </button>
                      ) :
                      <button
                          className="cta green ml280"
                      >
                        <object
                            id="loader"
                            type="image/svg+xml"
                            data={loaderSVG}
                        ></object>
                        {" "}
                        Prosegui
                      </button>
                  }
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
  );
}
