import AgentAvatar from "../assets/images/foto150x150.jpg";
import { NavLink } from "react-router-dom";
import { ClearSession } from "../service/session";
import React, { useEffect } from "react";
export default function StartScreen() {
  const RestartSession = () => {
    ClearSession();
  };

  useEffect(() => {
    RestartSession();
  }, []);

  return (
    <div className="page">
      <div className="fade-cover" />
      <main className="col-full">
        <NavLink to="/proprietario">
          <section className="col-full title_section text_center">
            <div className="center-block">
              <div className="col-group">
                <div className="col-full">
                  <div className="rform">
                    <div className="avatar">
                      <img src={AgentAvatar} alt="Agent Avatar" />
                    </div>
                    <h1 className="text_orange">
                      Ciao, sono Davide.
                      <br />
                      Ora ti chiederò qualche informazione per mostrarti il
                      preventivo su misura per te
                    </h1>
                    <br />
                    <br />
                    <div className="button_container">
                      <button className="cta green ml280">Prosegui</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </NavLink>
      </main>
    </div>
  );
}
