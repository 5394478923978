import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { NavigateTo } from "../helpers";
import { GetCivilStatuses, GetEducations, GetProfessions } from "../service";
import { GetItem, SetItem } from "../service/session";

export default function InfoScreen({ AgentAvatar }) {
  const H = useHistory();
  const [civilStatuses, SetCivilStatuses] = useState(null);
  const [educations, SetEducations] = useState(null);
  const [professions, SetProfessions] = useState(null);
  const [isReady, SetIsReady] = useState(false);

  const [civilStatus, SetCivilStatus] = useState("");
  const [profession, SetProfession] = useState("");
  const [education, SetEducation] = useState("");

  const FirstInput = useRef(null);

  const goToNextStep = () => {
    SetItem("U_PRO", profession);
    SetItem("U_EDU", education);
    SetItem("U_CST", civilStatus);
    NavigateTo({
      path: "/privacy",
      navigator: H,
    });
  };

  const SetOldData = () => {
    let s_profession = GetItem("U_PRO");
    let s_education = GetItem("U_EDU");
    let s_civilStatus = GetItem("U_CST");

    if (s_profession.data) {
      SetProfession(s_profession.data);
    }

    if (s_education.data !== null) {
      SetEducation(s_education.data);
    }

    if (s_civilStatus.data !== null) {
      SetCivilStatus(s_civilStatus.data);
    }
  };

  useEffect(() => {
    SetOldData();
    FirstInput.current.focus();
  },[]);

  useEffect(() => {
    const LoadFormData = async () => {
      if (!civilStatuses) {
        await GetCivilStatuses().then((res) => {
          SetCivilStatuses(res.data);
        });
      }
      if (!educations) {
        await GetEducations().then((res) => {
          SetEducations(res.data);
        });
      }
      if (!professions) {
        await GetProfessions().then((res) => {
          SetProfessions(res.data);
        });
      }
    };
    const CheckIfReady = () => {
      if (civilStatus !== "" && education !== "" && profession !== "") {
        SetIsReady(true);
        return;
      }
      SetIsReady(false);
      return;
    };

    CheckIfReady();
    LoadFormData();
  }, [
    civilStatus,
    education,
    profession,
    civilStatuses,
    educations,
    professions,
  ]);

  return (
    <div className="page">
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full title_section text_center">
          <div className="center-block">
            <div className="col-group">
              <div className="col-full">
                <div className="avatar">
                  <img src={AgentAvatar} alt="Agent Avatar" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
            <div className="col-full">
                <div className="rform">
              <fieldset>
                <label className="label" htmlFor="civilstatus">
                  Il tuo stato civile
                </label>
                <select
                  ref={FirstInput}
                  id="civilstatus"
                  name="civilstatus"
                  value={civilStatus}
                  onChange={({ target: { value } }) => {
                    SetCivilStatus(value);
                  }}
                >
                  <option value="">Scegli stato civile</option>
                  {civilStatuses &&
                    civilStatuses.map((_civilStatus) => {
                      return (
                        <option
                          key={`key_s_${_civilStatus.id}`}
                          value={_civilStatus.id}
                        >
                          {_civilStatus.value}
                        </option>
                      );
                    })}
                </select>
              </fieldset>

              <fieldset>
                <label className="label" htmlFor="titolostudio">
                  Titolo di studio
                </label>
                <select
                  id="titolostudio"
                  name="titolostudio"
                  value={education}
                  onChange={({ target: { value } }) => {
                    SetEducation(value);
                  }}
                >
                  <option value="">Scegli titolo di studio</option>
                  {educations &&
                    educations.map((_education) => {
                      return (
                        <option
                          key={`key_s_${_education.id}`}
                          value={_education.id}
                        >
                          {_education.value}
                        </option>
                      );
                    })}
                </select>
              </fieldset>

              <fieldset>
                <label className="label" htmlFor="professione">
                  Professione
                </label>
                <select
                  id="professione"
                  name="professione"
                  value={profession}
                  onChange={({ target: { value } }) => {
                    SetProfession(value);
                  }}
                >
                  <option value="">Scegli professione</option>
                  {professions &&
                    professions.map((_profession) => {
                      return (
                        <option
                          key={`key_s_${_profession.id}`}
                          value={_profession.id}
                        >
                          {_profession.value}
                        </option>
                      );
                    })}
                </select>
              </fieldset>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section className="col-full text_center form_section">
          <div className="center-block">
            <div className="col-group">
              <div className="button_container">
                <button
                  style={{
                    opacity: isReady ? 1 : 0.5,
                    transform: isReady ? "scale(1)" : "scale(.9)",
                    pointerEvents: isReady ? "all" : "none",
                  }}
                  className="cta green ml280"
                  onClick={goToNextStep}
                >
                  Prosegui
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
