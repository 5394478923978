import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { FilterArrayByKeyValue, NavigateTo } from "../helpers";
import { GetItem, SetItem } from "../service/session";
import "../assets/css/results.css";
import Arrow from "../assets/images/arrow.svg";
import ResultCard from "./components/ResultCard";

export default function PreventivoScreen({ SeoContent }) {
  const H = useHistory();
  const { data } = GetItem("INS_TYPE");
  const InsuranceType = data;
  let SEO = null;

  if (InsuranceType === "A") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "auto");
  }
  if (InsuranceType === "M") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "moto");
  }
  if (InsuranceType === "F") {
    SEO = SeoContent && FilterArrayByKeyValue(SeoContent, "slug", "autocarro");
  }

  return (
    <div className="page result_page">
      {SEO && (
        <Helmet>
          <title>{SEO && SEO.result.title}</title>
          <meta name="title" content={SEO && SEO.result.meta_title} />
          <meta
            name="description"
            content={SEO && SEO.result.meta_description}
          />
        </Helmet>
      )}
      <div className="fade-cover" />
      <main className="col-full">
        <section className="col-full form_section">
          <div className="center-block">
            <div className="col-group">
              <sidebar className="col-3 float_right">
                <div className="col-content riepilogo_dati">
                  <h3 id="toggle_riepilogo_dati" className="interaction_mobile">
                    Aggiungi garanzie
                    <span className="display_ib float_right hide_desktop">
                      <object
                        type="image/svg+xml"
                        data={Arrow}
                        className="icon icon_white icon_arrow "
                      ></object>
                    </span>
                  </h3>
                  <div className="col-full interaction_mobile_container p0">
                    <div className="riepilogo_dati_container">
                      <ul className="standard_check mt_0">
                        <li>
                          <input
                            name="test1"
                            data-name=""
                            type="checkbox"
                            value="1"
                            id=""
                          />
                          <label htmlFor="test1">Infortuni conducente</label>
                        </li>
                        <li>
                          <input
                            name="test2"
                            data-name=""
                            type="checkbox"
                            value="1"
                            id=""
                          />
                          <label htmlFor="test2">Infortuni conducente</label>
                        </li>
                        <li>
                          <input
                            name="test3"
                            data-name=""
                            type="checkbox"
                            value="1"
                            id=""
                          />
                          <label htmlFor="test3">Infortuni conducente</label>
                        </li>
                        <li>
                          <input
                            name=""
                            data-name=""
                            type="checkbox"
                            value="1"
                            id=""
                          />
                          <label>Infortuni conducente</label>
                        </li>
                      </ul>

                      <div className="sf col-full info_sidebar">
                        <div className="col-content">
                          <span>Informazioni sulle garanzie</span>
                          <p>
                            Passa con il mouse sulle garanzie sopra riportate
                            per leggere i dettagli
                          </p>
                        </div>
                      </div>

                      <div className="sf col-full info_sidebar">
                        <div className="col-content">
                          <span>Riepilogo dati</span>
                          <p>Modello</p>
                          <strong>FIAT Panda 2ª serie</strong>
                          <p>Decorrenza polizza</p>
                          <strong>21/11/2020</strong>
                          <p>
                            Riceverai una mail con l'offerta più conveniente su
                          </p>
                          <strong>tester@comparasemplice.it</strong>
                          <a
                            href="#"
                            className="cta cta_outline grey cta_medium cta_back"
                          >
                            Modifica dati
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </sidebar>
              <div className="col-9 float_left">
                <div className="col-content">
                  <div className="list_schede">

                    <ResultCard />

                    <ResultCard />

                    <ResultCard />

                  </div>

                  <div className="wp_pagenavi text_center m40_0">
                    <a className="previouspostslink" href="" rel="prev">
                      «
                    </a>
                    <a className="smaller" href="">
                      1
                    </a>
                    <a className="smaller" href="">
                      2
                    </a>
                    <span className="current">3</span>
                    <a className="larger" href="">
                      4
                    </a>
                    <a className="larger" href="">
                      5
                    </a>
                    <a className="nextpostslink" href="" rel="next">
                      »
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
